import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";  
import styles from "./PhlebotomyPill.module.scss"; 

export default function PhlebotomyPill(props) {   
  let pillClass = "";
  pillClass += props.disabled ? styles.disabled : ""
  pillClass += " ";
  pillClass += props.active ? styles.active+" "+styles.phlebotomyPill : styles.phlebotomyPill
 
  const onClickEvent = (phlebotomyId) => {
    if (props.phlebotomySelect) {
      props.phlebotomySelect(phlebotomyId);
    }
  }


  return ( 
    <div className={pillClass} onClick={() => onClickEvent(props.phlebotomyId)}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={"me-3 "+styles.checkBoxCircle}>{props.active ? <i className="fas fa-check"></i> : ''}</div>
        <div className="row align-items-center w-100">
          <div className="col col-md-auto text-break">
            <h5 className="m-0">{props.phlebotomyName}</h5>
          </div>
          <div className="ps-0 px-md-auto col-auto">
            <div className={styles.price+" ms-1 col-auto"}>({parseFloat(props.phlebotomyPrice) != "0.00"  ? '£'+parseFloat(props.phlebotomyPrice)  : 'FREE'})</div>
          </div>
          {props.showChangeBtn ? 
            <div className="ms-auto col-auto">
              <a className='ms-auto'>Change</a> 
            </div>
          : null}
        </div>
      </div> 
    </div>
  )
}
