import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import styles from "./SubscriptionOptions.module.scss"; 

export default function SubscriptionOptions(props) {  
  const [toggled, setToggled] = useState(false);  
  const setToggle = (e) => {
    setToggled(!toggled)
  }
  const subscriptionOptions = (testId, subscriptionAvailable) => {
    const partner = props.partner;
    var siteId = 2;

    if (global.config.websiteId == 1 || global.config.websiteId == 3) {
      siteId = 1;
    } else {
      siteId = 2;
    }

    var allowedSubscriptionMonths = [];
    var subscriptionOption = props.subscriptionOption;

    if (subscriptionAvailable == 1) {
      allowedSubscriptionMonths = [3, 4, 6, 12];
    } else if (subscriptionAvailable == 2) {
      allowedSubscriptionMonths = [3, 4, 6, 12];
    } else if (subscriptionAvailable == 3) {
      allowedSubscriptionMonths = [1, 3, 4, 6];
    }

    if (Object.keys(partner).length > 0 && partner[0].subscriptionTestsAsSingles == 1) {
      allowedSubscriptionMonths.push(0);
    }

    var stateVoucher = localStorage.getItem("voucher");
    var voucher = props.voucher;
    if (!voucher) {
      voucher = JSON.parse(stateVoucher);
    }

    if (siteId == 2) {
      if (subscriptionAvailable == 1) {
        allowedSubscriptionMonths = [1, 2, 3, 4, 6];
      } else if (subscriptionAvailable == 2) {
        allowedSubscriptionMonths = [1, 2, 3, 4, 6];
      } else if (subscriptionAvailable == 3) {
        allowedSubscriptionMonths = [1, 2, 3, 4, 6];
      }
    }

    if (voucher) {
      if (voucher.certainMonths.length > 0) {
        allowedSubscriptionMonths = voucher.certainMonths;
      }
    }

    var options = [];

    allowedSubscriptionMonths.forEach(function(option) {
      var opts = {};
      if (subscriptionOption != option) {
        if (option != 0) {
          options.push( 
            <div key={option} data-bs-toggle="collapse" href="#collapseSubscriptionOptions" role="button" aria-expanded="false" aria-controls="collapseSubscriptionOptions" onClick={() => { props.handleSubscriptionChange(option); setToggle(); }} className={styles.subscriptionPill}>
              <div className={"me-3 "+styles.pillCheckBoxCircle}><i className="fas fa-check"></i></div>
              <div className="d-flex">
                <h5 className="m-0">Every {option} {`${option == 1 ? "month" : "months"} `}</h5>
              </div> 
            </div>
          );
        } else {
          options.push(
            <div key={option} data-bs-toggle="collapse" href="#collapseSubscriptionOptions" role="button" aria-expanded="false" aria-controls="collapseSubscriptionOptions" onClick={() => { props.handleSubscriptionChange(option); setToggle(); }} className={styles.subscriptionPill}>
              <div className={"me-3 "+styles.pillCheckBoxCircle}><i className="fas fa-check"></i></div>
              <div className="d-flex">
                <h5 className="m-0">One-off</h5>
              </div> 
            </div> 
          );
        }
      }
    });
  
    if (subscriptionAvailable == 1) {
      return (
        <div className={"quantityOptonArea"}>
          {options} 
        </div>
      );
    }
  }

   

  return (   
    <div id={styles.selectedSubscriptionOption} className="row mt-3">
      <div className="col-12">
        <div className="row mb-1">
          <div className="col-12">
            <h4>Subscription frequency</h4>
          </div> 
        </div>
      </div>
      <div className="col-12">
        
        <div className={"card-block"}>   
          <div className={styles.card+" card " +styles.active}>
            <div className={styles.cardHeader+ " card-header"} id="headingThree">
         
              <h5 className="mb-0"> 
                <a className="collapsed d-flex align-items-center" onClick={() => { setToggle() }} data-bs-toggle="collapse" href="#collapseSubscriptionOptions" role="button" aria-expanded="false" aria-controls="collapseSubscriptionOptions">
                  <div className={"me-3 "+styles.checkBoxCircle}><i className="fas fa-check"></i></div>
                  <div className="d-flex">
                    {props.subscriptionOption > 0 ? 
                    <h5 className="m-0">Every {props.subscriptionOption} month{props.subscriptionOption > 1 ? 's' : ''}</h5>
                    :
                    <h5 className="m-0">One off purchase</h5>
                    }        
                  </div>
                  {toggled ? (
                    <i className="float-right far fa-chevron-up ps-3 ms-auto"></i>
                  ) : (
                    <i className="float-right far fa-chevron-down ps-3 ms-auto"></i>
                  )}
                   
                </a>
              </h5>
            </div>
            <div id="collapseSubscriptionOptions" className="collapse" >
              <div className="card-body"> 
                {subscriptionOptions(props.testId, props.test.subscriptionAvailable)}
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
  )
}