import React from 'react'
import styles from './LoadingSpinner.module.scss'

export default function LoadingSpinner({size = "md", className}) {
  return (
    <div data-size={size} className={`${styles.spinner}`} title="spinner">
      <i className={`fas fa-spinner fa-fw fa-${size}`}></i>
    </div>
  )
}
