import React, { useEffect } from "react";

import { CheckoutFooter, RenderIf } from "../../../components";

import styles from "./CheckoutSkeleton.module.scss";


export default function CheckoutSkeleton() {
  const isForth = window.config.websiteId == 1;
  const isEdge = window.config.websiteId == 2;
  const isBiomarkerTracking = !isForth && !isEdge;

  const basket = localStorage.getItem("basket");
  const basketLength = basket ? [...new Set(Object.values(JSON.parse(basket)))].length : 1;

  return (
    <div className={`${styles.container} container-full gx-0 d-flex flex-column`}>
      <header className="py-4" data-variant={isEdge ? "edge" : "forth"}>
        <div className={`container px-3`}>
          <div className={`row g-0 align-items-center placeholder-glow`}>
            <RenderIf isTrue={isBiomarkerTracking}>
              <div className={`d-none d-md-block col-2 placeholder ${styles.logo}`}></div> 
            </RenderIf>
            <RenderIf isTrue={!isBiomarkerTracking}>
              <a href={isForth ? "https://forthwithlife.co.uk" : "https://sportsbloodtests.co.uk"} className={`d-none d-md-block col-2 ${styles.logo}`}>
                <img class="img-fluid d-none d-sm-block" src={isForth ? "/img/forthBlack.png" : "/img/edgeWhiteLogo.svg"} />
              </a> 
            </RenderIf>
            <h1 className="mb-0 col-md-8 text-center">Your Basket</h1>
            <div className="col-2"></div>
          </div>
        </div>
      </header>

      <main className={`flow-grow-1 container pt-4`}>
        <div className={`row gx-5`}>
          <div className={`col-12 col-lg-8 placeholder-glow`}>
          
            {Array.from(Array(basketLength).keys()).map((_, i) => (
              <article className={`row mx-0 ${i > 0 ? "mt-4" : ""}`} key={i}>
                <div className={`col-auto placeholder pe-3 ${styles.productImage}`}></div>
                <div className={`col placeholder-glow ms-4 d-flex flex-column ${styles.productInfo}`}>
                  <h3 className="placeholder">Product name</h3>
                  <p className="placeholder">£<span className="fs-4 pe-4">0.00</span></p>
                  <div className={`col-auto placeholder-glow d-flex gap-3 align-items-center ${styles.productQty}`}>
                    <span className="placeholder">QTY</span>
                    <div className={`d-flex placeholder ${styles.qtyBtn}`}>
                      <button><i className="far fa-trash"></i></button>
                      <input type="text"></input>
                      <button>+</button>
                    </div>
                  </div>
                </div>
              </article>
            ))}

            <h4 className="placeholder mt-5">Collection method</h4>
            <div className={`${styles.pill} d-flex`}>
              <span className={`placeholder me-3 ${styles.pillCircle}`}></span>
              <p className={`m-0 placeholder ${styles.pillText} pe-5`}>Finger prick kit (FREE)</p>
              <span className="placeholder ms-auto me-0">Change</span>
            </div>
          </div>
          <div className={`col-12 col-lg-4 pb-5 placeholder-glow mt-5 pt-5 pt-lg-0 mt-lg-0`}>
            <div className="row mx-0">
              <p className="mb-1 placeholder col-auto"> Discount code:</p>
              <div className={`col-12 d-flex gap-2 gx-0 ${styles.voucherContainer}`}>
                <input type="text" className="placeholder py-2 col"></input>
                <button className="placeholder col-auto">Apply</button>
              </div>
            </div>
            <div className="row mx-0 mt-4 justify-content-between align-items-center">
              <p className="w-auto mb-0 placeholder">Subtotal</p>
              <span className="placeholder w-auto">£00.00</span>
            </div>
            <div className="row mx-0 mt-4 justify-content-between align-items-center">
              <p className="w-auto mb-0 placeholder">Finger prick kit</p>
              <span className="placeholder w-auto">FREE</span>
            </div>
            <div className="row mx-0 mt-4 justify-content-between align-items-center">
              <p className="w-auto mb-0 placeholder">Tacked 24 delivery</p>
              <span className="placeholder w-auto">£0.00</span>
            </div>
            <hr className="my-4"/>
            <div className={`${styles.klarna} text-center`}>
              <span className={`placeholder me-2 ${styles.paymentIcon}`}/> 
              <p className="mb-0 placeholder">3 interest-free payments of £0.00&pound;</p>
            </div>
            <div className="row mx-0 mt-3 justify-content-center gap-2 align-items-center">
              <span className={`placeholder me-2 ${styles.paymentIcon}`}/> 
              <span className={`placeholder me-2 ${styles.paymentIcon}`}/> 
              <span className={`placeholder me-2 ${styles.paymentIcon}`}/> 
              <span className={`placeholder me-2 ${styles.paymentIcon}`}/> 
              <span className={`placeholder me-2 ${styles.paymentIcon}`}/> 
            </div>
            <hr className="my-4"/>
            <div className="row mx-0 mt-4  gy-4 justify-content-center gap-2 align-items-center">
              <button className="placeholder btn w-100">Pay by Credit/Debit</button>
              <a className="placeholder w-auto">Continue shopping</a>
            </div>
          </div>
        </div>
      </main>
      <CheckoutFooter partner={{}} isEdge={isEdge} isLoadingLogo={isBiomarkerTracking} />
    </div>
  );
}
