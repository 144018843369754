import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";  
import ReactPlayer from 'react-player';
import styles from "./HowToCollect.module.scss"; 

export default function HowToCollect(props) {   

  const config = {
    vimeo: {
      playerOptions: {
        playsinline: true
      }
    }
  }

  return ( 
    <div className="row">
      <div className="col-12 text-center">
        {global.config.websiteId == 1 
          ? <img className="mb-4 mt-5 modalIcon" src="/img/forthGradientIcon.png"/>
          : <img className={`${styles.logo} mb-4 mt-5 modalIcon`} src="/img/FE-Droplet-Glow.png" alt="forth edge logo" />
        }
        <h1 className="mb-5">How to collect your sample</h1>
      </div> 
      <div className="col-12 text-center">
        <p>Our finger prick blood test kit has everything you need. Just collect your sample, and return it to our accredited lab, using the Tracked-24 envelope supplied.</p>

        <p>Watch Dr Thom Phillips demonstrate how to take a successful finger prick sample.</p>
      </div>
      <div className="col-12 text-center mt-3">
        <ReactPlayer 
          url="https://vimeo.com/714439018" 
          width="100%" 
          height="250px"
          controls="true"
          config={config}
          />
      </div>
    </div>
  )
}
