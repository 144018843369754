import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { Button } from '../..';
import DeliveryPill from '../DeliveryPill';
import RenderIf from '../../RenderIf/RenderIf'
import styles from './DeliverySelector.module.scss'
import getDeliveryCopy from "../../../helpers/getDeliveryCopy"
import { Tooltip } from 'bootstrap';

export default function DeliverySelector(props) {
  const { deliveryOption, nextDayPrice, updateDeliveryOption, allowInternational, deliveryInfo, deliverySettings, deliveryPrice} = props
  const [deliveryOptionSelected, setDeliveryOptionSelected] = useState(deliveryOption)

  useEffect(() => {
    setDeliveryOptionSelected(deliveryOption)
  },[deliveryOption])  

  const submitDeliveryOption = (option) => {
    updateDeliveryOption(option)
  }

  let deliveryCopy = getDeliveryCopy();
  let nextDayTitle = deliveryCopy.nextDayTitle;
  let nextDayDescription = deliveryCopy.nextDayDescription;
  if (parseInt(props.deliveryInfo?.nationalHoliday) == 1) {
    nextDayTitle = "Guaranteed Next Working Day";
  } 

  return (
    <div className={styles.contentBlock}>
      <div className="row">
        <div className="col-12 mb-4">
          <button type="button" className={"back-button "+styles.modalBack} data-bs-dismiss="modal" aria-label="Close"><i className="fal fa-chevron-left me-2"></i> Back</button>
        </div> 
        <div className="col-12 text-center">
          <h2 className="mb-4">Delivery options</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-12" onClick={() => setDeliveryOptionSelected(0)}>
          <div className="mb-2">
            <DeliveryPill deliveryOption={0} nextDayTitle={nextDayTitle} nextDayPrice={nextDayPrice} active={deliveryOptionSelected == 0 ? true : false} deliveryInfo={deliverySettings || deliveryInfo} deliveryPrice={deliveryPrice}/> 
          </div>
          <p>Your kit will be dispatched within 1 working day and sent via Tracked 24. You will receive your tracking number via SMS. The kit will fit through your letter box.</p>
        </div>

        <RenderIf isTrue={parseInt(props.showNextDayDelivery) == 1} >
          <div className={`col-12 ${allowInternational ? styles.disabled : ""}`} onClick={() => setDeliveryOptionSelected(1)}>
            <div className="mb-2">
              <DeliveryPill deliveryOption={1} nextDayTitle={nextDayTitle} nextDayPrice={nextDayPrice} active={deliveryOptionSelected == 1 ? true : false} deliveryInfo={deliverySettings || deliveryInfo} deliveryPrice={deliveryPrice}/> 
            </div>
            
            <RenderIf isTrue={parseInt(props.deliveryInfo?.nationalHoliday) == 1}>
              <p className="mt-2 mb-0 text-danger"><strong>{props.deliveryInfo?.nationalHolidayCopyOverride}</strong></p>
            </RenderIf>
            <RenderIf isTrue={parseInt(props.deliveryInfo?.nationalHoliday) == 0}>
              <p className={`${allowInternational ? styles.disabled : ""}`}>{nextDayDescription}</p>
            </RenderIf>
          </div> 
        </RenderIf>
        
      </div>
      
      <div className="row mt-auto">
        <div className="col-12 mb-5">
          <Button variant="default" onClick={() => submitDeliveryOption(deliveryOptionSelected)} data-bs-dismiss="modal" aria-label="Close" className={"w-100 mt-4"}>
            Update basket
          </Button>
        </div>
      </div>

    </div>
  );
}