import styles from './Radio.module.scss'

export default function Radio({onClick= () => {}, options = [], optionsDirection = "row", name= "", defaultValue = "", value = "", className = "", disabled = false, ...rest}) {
  return (
    <>
      <div className="row">
        {options?.map((option, i) => {
          const {name: optionName, value: optionValue, score, description = "", descriptionClass = ""} = option

          return (
            <div className={`${className.includes("col") ? "" : "col-6"} ${className}`}>
              <div className={`${(defaultValue == optionValue ||  value == optionValue) ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${disabled ? styles.disabled : ""}`} onClick={(e) => onClick(name, optionValue, score)} {...rest}> 
                <div className={`d-flex ${optionsDirection === "column" ? "flex-column" : ""} align-items-center`}>
                  <div className={styles.checkBoxCircle}>
                    {(defaultValue == optionValue ||  value == optionValue) ? <i className="fas fa-check"></i> : ''}
                  </div>
                  <div className="d-flex">
                    <h5 className="m-0">{optionName}</h5> 
                  </div>
                </div> 
              </div> 
              {description && <small className={`fw-normal d-block text-pretty pt-2 ${descriptionClass}`}>{description}</small>}
            </div>
          );
        })}
      </div>
    </>
  )
}