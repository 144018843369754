import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import styles from "./CheckoutHeader.module.scss";
import getLogo from "../../../helpers/getLogo";

export default function CheckoutHeader(props) {  
  
  let step = props.step;
  let websiteId = global.config.websiteId;
 
  let referrer = localStorage.getItem('referrer');
  if (referrer) {
    referrer = referrer.replace(/^"(.*)"$/, '$1');
  }
  const partner = props.partner;
  let partnerPortalId = localStorage.getItem('partnerPortalId');
  if (partnerPortalId == 'undefined' || !partnerPortalId) {
    partnerPortalId = 0;
  }

  let title; 
  let logo = getLogo(websiteId, partner)
  let url;
  let redirectUrl;
  let reload; 
  let homePageUrl;
   
  switch (step) {
    case 0:
      title = 'Your Basket'; 
      break;
    default:
      title = 'Checkout';
  }

  if (referrer) { 
    url = referrer.split('/')[2]; 
    if (url == 'www.forthwithlife.co.uk' || url == 'www.forthedge.co.uk' ||  url == 'sportsbloodtests.co.uk' || url == 'www.sportsbloodtests.co.uk' || url == 'shop.forthwithlife.co.uk' || url == 'shop.forthedge.co.uk' || 'shop.sportsbloodtests.co.uk' || url.includes("biomarkertracking.com")) {
      if (url == 'shop.forthwithlife.co.uk') {
        redirectUrl = 'https://www.forthwithlife.co.uk';
        props.step == 1 && localStorage.setItem('partnerPortalId', 0);
      } else if (url == 'shop.forthedge.co.uk' || url == 'shop.sportsbloodtests.co.uk') {
        redirectUrl = 'https://www.sportsbloodtests.co.uk';
        props.step == 1 && localStorage.setItem('partnerPortalId', 0);
      } else if (url.includes("biomarkertracking.com") && Object.keys(partner).length > 0) {
        redirectUrl = 'https://biomarkertracking.com/'+partner[0].requestCode;
      } else { 
        redirectUrl = referrer;
      } 
    } else { 
      if (props.step == 1  || props.step == 3) {
        reload = true;
      } else {
        redirectUrl = '/'
      } 
      if (props.step == 4 && !props.paymentSuccess) {
        reload = true;
      }
    }
  } else {
    if (props.step == 1 || props.step == 3) {
      reload = true;
    } else {
      if (window.location.hostname == 'shop.forthwithlife.co.uk') {
        redirectUrl = 'https://forthwithlife.co.uk';
      } else if (window.location.hostname == 'shop.forthedge.co.uk' || window.location.hostname == 'shop.sportsbloodtests.co.uk') {
        redirectUrl = 'https://sportsbloodtests.co.uk';
      } else if (window.location.hostname.includes("biomarkertracking.com") && Object.keys(partner).length > 0){
        redirectUrl = 'https://biomarkertracking.com/'+partner[0].requestCode;
      } else {
        redirectUrl = '/'
      }
    } 
  }

  if (['shop.forthwithlife.co.uk','appdev.forthwithlife.co.uk', 'localhost'].includes(window.location.hostname)) {
    homePageUrl = 'https://forthwithlife.co.uk';
  } else if (window.location.hostname == 'shop.forthedge.co.uk' || window.location.hostname == 'shop.sportsbloodtests.co.uk') {
    homePageUrl = 'https://sportsbloodtests.co.uk';
  } else if (window.location.hostname.includes("biomarkertracking.com") && Object.keys(partner).length > 0){
    homePageUrl = 'https://biomarkertracking.com/'+partner[0].requestCode;
  } else {
    homePageUrl = '/'
  }

  const sendCloseIframe = (e) => {
    e.preventDefault();
    if(window.parent) {
      window.parent.postMessage('closeIframe', '*');
    }
  }
  
  let showHeader = true; 
  if (window != window.parent || window.self != window.top || window.location != window.parent.location) {  
    showHeader = false;
  }

  
  return (  
    <>
      {showHeader && (
        <header id={styles.checkoutHeader} className="py-4">
          <div className="container">
            <div className="row g-0 align-items-center">
              <div className="col-2"> 
                <a href={homePageUrl}><img className={`img-fluid d-none d-sm-block bespokeTestLogo ` + styles.logo } src={logo} /></a>
              </div> 
              <div className="col-8 text-center"> 
                <h1 className="m-0 p-0">{title}</h1> 
              </div> 
            
              {props.step > 0 && (
                <div className="d-none d-md-block col-2 text-end">
                  <a className={styles.close} href="/checkout"> <i className="fas fa-chevron-left me-2"></i> Return to basket</a>
                </div>
              )}  
              {props.step == 0 && (
                <div className="col-2 d-lg-none text-end"> 
                  <React.Fragment>
                    <a href={redirectUrl} className={styles.close}><i className="me-2 far fa-chevron-left"></i> Back</a> 
                  </React.Fragment> 
                </div> 
              )}
              {window != window.parent && props.step > 0 && (
                <div className="d-block d-md-none  col-2 text-end">
                  <React.Fragment>
                    <a onClick={(e) => { sendCloseIframe(e) }} className={styles.close}>Close <i className="ms-2 far fa-times"></i></a> 
                  </React.Fragment> 
                </div>
              )}

              {window == window.parent && (
                <div className="d-none d-md-none  col-2 text-end">

                  {props.checkoutFromPartner == 1 ? 
                    <React.Fragment>
                      <a onClick={(e) => { sendCloseIframe(e) }} className={styles.close} href={'https://forthpartners.co.uk'}> <i className="fas fa-chevron-left me-2"></i>Close </a>
                    </React.Fragment>
                  : reload ? (
                    <React.Fragment>
                      <a onClick={(e) => { sendCloseIframe(e); window.location.reload()}} className={styles.close}> <i className="fas fa-chevron-left me-2"></i>Close </a> 
                    </React.Fragment>
                  ) : ( 
                    <React.Fragment>
                      <a onClick={(e) => { sendCloseIframe(e) }} className={styles.close} href={redirectUrl}>Close <i className="ms-2 far fa-times"></i></a>
                    </React.Fragment>
                  )} 
                </div>
              )} 
            </div>
          </div>
        </header>
      )} 
    </>
     
 
  )
}