import React from 'react';
import PropTypes from 'prop-types';
import styles from "./SidebarModal.module.scss"; 


export default function SidebarModal({ children, modalId, hideHeader = false, isStatic = false, className = "", variant="sidebar"}) {
  return(
    <div className={`modal ${variant === "sidebar" ? styles.sidebarModal : ""} ${className}`} tabIndex="-1" role="dialog" id={modalId} data-bs-backdrop={isStatic ? "static" : true}>
      <div className={"modal-dialog " + styles.modalDialog} role="document">
        <div className={"modal-content shadow-sm border-0 " + styles.modalContent}>
          {!hideHeader && (
          <div className="modal-header border-0">
            <button type="button" className={"back-button "+styles.modalBack} data-bs-dismiss="modal" aria-label="Close"><i className="fal fa-chevron-left me-2"></i> Back</button>
          </div> 
          )}
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

SidebarModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element), 
    PropTypes.element.isRequired
  ]),
  modalId: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
}