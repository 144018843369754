import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, Provider } from 'react-redux'
import { Route, Switch, withRouter, matchPath, Redirect } from 'react-router-dom'
 
import ReactGA from 'react-ga'; 
import ReactPixel from 'react-facebook-pixel';
import base64 from 'base-64' 


import { loadUser } from '../actions/users-actions'
import { getPartnerUserDetails, loadPartnerShopDetails, loadPartnerUser, loadPartnerDetails } from '../actions/partners-actions'
import { loadValidate, logout } from '../actions/authentication-actions'
import { loadPhlebotomyPrices, setPhlebotomyPrices } from '../actions/phlebotomy-actions'
import { loadKitPrices, setKitPrices } from '../actions/kit-actions'
import { loadReferCodeDetails, setReferCodeDetails } from '../actions/vouchers-actions'
import { loadCheckoutSettings } from "../actions/app-actions"
import { setDeliveryOptions, getDeliveryOptions} from '../actions/basket-actions'

import asyncComponent from "../components/AsyncComponent";
import PrivateRoute from '../components/PrivateRoute'
import RestrictedRoute from '../components/RestrictedRoute';
import CustomRedirect from "../components/Global/Redirect"

import Footer from '../components/Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import PartnerHeader from './../components/PartnerManagement/PartnerHeader'
import PartnerFooter from './../components/PartnerManagement/PartnerFooter'
import { isLightColour } from '../utils';
import config from '../config';
import _, { isEmpty } from 'lodash';
import $ from 'jquery'
import { aesDecrypt } from '../helpers/aesEncrypt';



const SubNavigation = asyncComponent(() => import("../containers/SubNavigation"));
const Page404 = asyncComponent(() => import("../containers/Page404"));

const PartnerProgram = asyncComponent(() => import("../containers/PartnerSignUp"));
const PartnerSignIn = asyncComponent(() => import("../containers/PartnerSignIn"));
const PartnerUpdatePassword = asyncComponent(() => import("../containers/PartnerUpdatePassword"));
const PartnerManagement = asyncComponent(() => import("../containers/PartnerManagement"));
const PartnerOrders = asyncComponent(() => import("../containers/PartnerOrders"));
const PartnerNewOrder = asyncComponent(() => import("../containers/PartnerNewOrder"));
const PartnerNewOrderInfo = asyncComponent(() => import("../containers/PartnerNewOrderInfo"));
const PartnerResults = asyncComponent(() => import("../containers/PartnerResults"));
const PartnerClients = asyncComponent(() => import("../containers/PartnerClients"));
const PartnerEditClient = asyncComponent(() => import("../containers/PartnerEditClient"));
const PartnerAddClient = asyncComponent(() => import("../containers/PartnerAddClient"));
const PartnerClientResult = asyncComponent(() => import("../containers/PartnerClientResult"));
const PartnerClientResultDetails = asyncComponent(() => import("../containers/PartnerClientResultDetails"));
const PartnerClientBiomarkerDetails = asyncComponent(() => import("../containers/PartnerClientBiomarkerDetails"));
const PartnerAccount = asyncComponent(() => import("../containers/PartnerAccount"));
const PartnerSettings = asyncComponent(() => import("../containers/PartnerSettings"));
const PartnerTests = asyncComponent(() => import("../containers/PartnerTests"));
const PartnerTestEdit = asyncComponent(() => import("../containers/PartnerTestEdit"));
const PartnerTestAdd = asyncComponent(() => import("../containers/PartnerTestAdd"));
const PartnerUsers = asyncComponent(() => import("../containers/PartnerUsers"));
const PartnerAddUser = asyncComponent(() => import("../containers/PartnerAddUser"));
const PartnerEditUser = asyncComponent(() => import("../containers/PartnerEditUser"));
const Partnership = asyncComponent(() => import("../containers/Partnership"));
const PartnerStoreItem = asyncComponent(() => import("../containers/Partner/PartnerStoreItem"));
const PartnerShop = asyncComponent(() => import("../containers/Partner/PartnerShop"));
const PartnershipContainer = asyncComponent(() => import("../containers/Partner/Partnership"));
const PartnerStoreHowItWorks  = asyncComponent(() => import("../containers/Partner/PartnerStoreHowItWorks"));
const PartnerRedirect  = asyncComponent(() => import("../containers/PartnerRedirect"));
const PartnerTermsAndConditions  = asyncComponent(() => import("../containers/PartnerTermsAndConditions"));
const PartnerCommision  = asyncComponent(() => import("../containers/PartnerCommision"));
const Loader  = asyncComponent(() => import("./../components/Loader")); 
const Checkout = asyncComponent(() => import("../containers/Checkout/Checkout"));  
const CheckoutKlarnaSuccess = asyncComponent(() => import("../containers/Checkout/CheckoutKlarnaSuccess")); 
const CheckoutFromWebsite = asyncComponent(() => import("../containers/Checkout/CheckoutFromWebsite"));
const CheckoutFromWebsiteV2 = asyncComponent(() => import("../containers/Checkout/CheckoutFromWebsiteV2"));
const CheckoutFromPartner  = asyncComponent(() => import("../containers/Checkout/CheckoutFromPartner")); 
const ReferVoucher  = asyncComponent(() => import("../containers/ReferVoucher/ReferVoucher"));
const PartnerPlaceOrder  = asyncComponent(() => import("../containers/Partner/PartnerOrders/PartnerPlaceOrder"));
const ActivationKit = asyncComponent(() => import("../containers/Partner/ActivationKit/ActivationKit"));
const RedirectToBespoke = asyncComponent(() => import("../containers/RedirectToBespoke"));
const BlankKits = asyncComponent(() => import("../containers/Partner/BlankKits/BlankKits"));


class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,  
    loadUser: PropTypes.func.isRequired,
    loadPhlebotomyPrices: PropTypes.func.isRequired,
    loadKitPrices: PropTypes.func.isRequired,
    loadValidate: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      accessSuperAdmin: 0, 
      accessUserManagment: 0, 
      accessSettings: 0, 
      accessResults: 0, 
      accessFinancial: 0, 
      accessClientsOrders: 0,
      shopLoaded: 0,
      partnerHasAccepted: 0,
      partnerPreview: 0
    }

  }

  componentWillMount() { 
    const queryParams = new URLSearchParams(window.location.search)
    const toPath = queryParams.get("to")
    const partnerToken = queryParams.get("pt") || ""
    const newBespokeTestIds = queryParams.get("tests")?.split(",") || []
    
    if(newBespokeTestIds.length > 0){
      const currentBespokeTestIds = JSON.parse(localStorage.getItem("newBespokeTestIds")) || []
      const currentBlankKitBespokeTestIds = JSON.parse(localStorage.getItem("newBlankKitBespokeTestIds")) || []
      
      toPath == "stocked-kits"
        ? localStorage.setItem("newBlankKitBespokeTestIds", JSON.stringify(new Set([...newBespokeTestIds, ...currentBlankKitBespokeTestIds])))
        : localStorage.setItem("newBespokeTestIds", JSON.stringify(new Set([...newBespokeTestIds, ...currentBespokeTestIds])))
    }

    const partnerTokenDecrypted = aesDecrypt(decodeURIComponent(partnerToken))

    if(partnerTokenDecrypted){
      localStorage.setItem("token", partnerTokenDecrypted)
    }
    
    const token = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;
    const authenticated = this.props.authentication.isAuthenticated;

    this.props.loadCheckoutSettings().then(({response}) => {
      if(response?.error) {
        console.error("Error loading checkout settings").
        return 
      }

      const {kitPrices = [], phlebotomyPrices = [], referralCodeInformation = {}, deliveryOptions = {}} = response || {}
      
      if(!isEmpty(kitPrices)) {
        this.props.setKitPrices(kitPrices)
      } else {
        this.props.loadKitPrices();
      }

      if(!isEmpty(phlebotomyPrices)) {
        this.props.setPhlebotomyPrices(phlebotomyPrices)
      } else {
        this.props.loadPhlebotomyPrices();
      }

      if(!isEmpty(referralCodeInformation)) {
        this.props.setReferCodeDetails(referralCodeInformation)
      } else {
        this.props.loadReferCodeDetails()
      }

      if(!isEmpty(deliveryOptions)){
        this.props.setDeliveryOptions(deliveryOptions)
      } else {
        this.props.getDeliveryOptions()
      }

    })
    
    if (token && authenticated) { 
      this.props.loadValidate()
        .then((response) => {
          global.config.partnerPlatform == 0 && !(localStorage.getItem("FromPartnerPortal") == 1)? this.props.loadUser('me') : this.props.loadPartnerUser('me').then((response)=>{
            this.props.loadPartnerDetails('me')
            this.setState({
              accessSuperAdmin: parseInt(this.props.user.accessSuperAdmin), 
              accessUserManagment: parseInt(this.props.user.accessUserManagment), 
              accessSettings: parseInt(this.props.user.accessSettings), 
              accessResults: parseInt(this.props.user.accessResults), 
              accessFinancial: parseInt(this.props.user.accessFinancial), 
              accessClientsOrders: parseInt(this.props.user.accessClientsOrders)
            })
            
            if(toPath) {
              this.props.history.push(toPath)
            }
          })
        })
    }
    this.props.history.listen(location => window.scrollTo(0, 0));
  }
 

  componentDidMount() {
    if (process.env.NODE_ENV != 'development') {
      ReactPixel.init('184476205260993');
      ReactPixel.pageView();
      ReactPixel.fbq('track', 'PageView');
    }   
    if(global.config.partnerStore == 1){
      const requestCode = window.location.href
      let code, data, dataIndex, partnerColour1, partnerColour2, partnerColour2Lighter, partnerColour2Darker, partnerColour3, partnerColour3Lighter, partnerColour3Darker

      if(requestCode.includes("?previewData=")){
        code = requestCode.split("/")[3].split("?")[0]
        dataIndex = requestCode.split("/").map( (params, index) => {
          if(params.indexOf("?previewData=") != "-1"){
            return index
          }
        }).filter( v => v != undefined).toString()
       
        data = requestCode.split("/")[dataIndex].split("?")[1].slice(2)
        data = data.replace('-', '/');

        try {
          data = JSON.parse(base64.decode(data))
        } catch (error) {
          data = ""
        }
        
      }else{
        code = requestCode.split("?")[0]
        code = code.split("/")[3]
      }
      
      this.props.loadPartnerShopDetails({requestCode: code}).then( (response) => {
        if(!response.error){
          this.setState({
            shopLoaded: 1,
            partnerHasAccepted: response.response.entities.partnerShop[0].termsAndConditionsAccepted
          })
          
          if(data){
            partnerColour1 = data.base
            partnerColour2 = data.primary
            partnerColour2Lighter = data.primaryLighter 
            partnerColour2Darker = data.primaryDarker
            partnerColour3 = data.secondary
            partnerColour3Lighter = data.secondaryLighter
            partnerColour3Darker = data.secondaryDarker
            this.setState({
              partnerPreview: 1
            })
          }else{
            partnerColour1 = response.response.entities.partnerShop[0].partnerColour1 || "#6a8f93"
            partnerColour2 = response.response.entities.partnerShop[0].partnerColour2 || "#faa240"
            partnerColour2Lighter = response.response.entities.partnerShop[0].partnerColour2Lighter 
            partnerColour2Darker = response.response.entities.partnerShop[0].partnerColour2Darker
            partnerColour3 = response.response.entities.partnerShop[0].partnerColour3
            partnerColour3Lighter = response.response.entities.partnerShop[0].partnerColour3Lighter
            partnerColour3Darker = response.response.entities.partnerShop[0].partnerColour3Darker
          }
          
          if(["#000", "#000000"].includes(partnerColour1)){
            const body = document.body;
            body.classList.add("dark");
            var root = document.querySelector("body.dark .partner")
          }

          if(["#fff", "#ffffff"].includes(partnerColour1)){
            const body = document.body;
            body.classList.add("light");
            var root = document.querySelector("body.light .partner")
          }

          if(root){
            root.style.setProperty('--base', partnerColour1)
            root.style.setProperty('--primary', partnerColour2)
            root.style.setProperty('--primary-lighter', partnerColour2Lighter)
            root.style.setProperty('--primary-darker', partnerColour2Darker)
            root.style.setProperty('--secondary', partnerColour3)
            root.style.setProperty('--secondary-lighter', partnerColour3Lighter)
            root.style.setProperty('--secondary-darker', partnerColour3Darker)
            root.style.setProperty('--checkbox-icon', isLightColour(partnerColour2) ? "url(/img/checkDark.png)" : "url(/img/check.png)")
          }
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
		$('.modal-backdrop').remove()
    if (this.props.location !== prevProps.location) {
      if (process.env.NODE_ENV != 'development') {
        ReactPixel.init('184476205260993');
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'PageView');
      } 
    }
  }
 
 
  render() {
    const { store, partner } = this.props
    const hasAccessToShockedKits = isEmpty(partner) ? undefined : partner[0]?.showStockedKits == 1
    const user = this.props.user;
    const userId = user.iD; 
    var isIosWebview =/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = window.isRNWebView;
 
    if (userId == 94 || userId == 405 || userId == 41206) {
      document.body.classList.add('demo');
    } else {
      document.body.classList.remove('demo');
    }

    var container = true;
    let rootUrl = "https://app.forthwithlife.co.uk"; 
    if (global.config.websiteId == 1) {
      rootUrl = "https://app.forthwithlife.co.uk";
    } else if (global.config.websiteId == 2) { 
      rootUrl = "https://shop.sportsbloodtests.co.uk";
    } else {
      rootUrl = "https://shop.biomarkertracking.com";
    } 
    if(["appdev.forthwithlife.co.uk", "appdev.forthedge.co.uk", "appdev.sportsbloodtests.co.uk"].includes(window.location.hostname)){
      rootUrl =  global.config.websiteId == 1 ? "https://app.forthwithlife.co.uk" : "https://shop.sportsbloodtests.co.uk"
    }

    if (global.config.partnerStore == 1) {
      container = false;
    }
       
    if (this.props.location.pathname.includes("/checkout")) {
      container = false;
    }


    if (this.props.location.pathname === "/sign-in" || this.props.location.pathname === "/demo" || this.props.location.pathname === "/fhmdemo") {
      if (global.config.partnerPlatform != 1) {
        return (
          <Provider store={store}>
            <main role="main" id="login-page">
              <Switch>
                <Route exact path="/(sign-in|demo|fhmdemo)" component={Login} />
              </Switch>
            </main>
          </Provider>
        )
      } else {
        if(this.props.authentication.isAuthenticated){
          return (
            <Provider store={store}>
              <main role="main" id="login-page">
                <Switch>
                  <Redirect to={'/'} />
                </Switch>
              </main>
            </Provider>
          )
        }else{
          return (
            <Provider store={store}>
              <main role="main" className="login-page">
                <Switch>
                  <Route exact path="/(sign-in)" component={PartnerSignIn}/>
                </Switch>
              </main>
            </Provider>
          )
        }
      } 
    } else if (matchPath(this.props.location.pathname, { path: '/p/', exact: true, strict: true })) {
      return (
        <Provider store={store}>
          <main role="main" id="login-page">
            <Switch>
              <Redirect to={'/'} />
            </Switch>
          </main>
        </Provider>
      )
    } else if (matchPath(this.props.location.pathname, { path: '/activate', exact: true, strict: true })) {
      const redirectTo = {
       1: "https://app.forthwithlife.co.uk/activate",
       2: "https://app.sportsbloodtests.co.uk/activate",
       3: "https://app.biomarkertracking.com/activate",
       4: "https://app.biomarkertracking.com/activate"
      }

      return window.location.replace(redirectTo[global.config.websiteId] ?? redirectTo[1]);
    } else if (matchPath(this.props.location.pathname, { path: '/forgotten-password/:token' })) {
      if (global.config.partnerPlatform != 1) {
        return (
          <Provider store={store}>
            <main role="main" id="login-page">
              <Switch>
                <Route exact path="/forgotten-password/:token" component={UpdatePassword} /> 
              </Switch>
            </main>
          </Provider>
        )
      } else {
        return (
          <Provider store={store}>
            <main role="main" className="login-page">
              <Switch>
                <Route exact path="/forgotten-password/:token" component={PartnerUpdatePassword} /> 
              </Switch>
            </main>
          </Provider>
        )
      }
    
    }  else if (matchPath(this.props.location.pathname, { path: '/forgotten-password/:token' })) {
      if (global.config.partnerPlatform != 1) {
        return (
          <Provider store={store}>
            <main role="main" id="login-page">
              <Switch>
                <Route exact path="/forgotten-password/:token" component={UpdatePassword} /> 
              </Switch>
            </main>
          </Provider>
        )
      } else {
        return (
          <Provider store={store}>
            <main role="main" className="login-page">
              <Switch>
                <Route exact path="/forgotten-password/:token" component={PartnerUpdatePassword} /> 
              </Switch>
            </main>
          </Provider>
        )
      } 
    }else if (matchPath(this.props.location.pathname, { path: '/p/store/:param', exact: true, strict: true })) {
      
      const match = matchPath(this.props.location.pathname, { 
        path: "/p/store/:param"
      });

      let shopId;

      // match can be null
      if (match && match.params.param) {
        shopId = match.params.param;
      }
      return (
        <Provider store={store}>
          <main role="main" id="login-page">
            <Switch>
              <Redirect to={'/'+shopId} />
            </Switch>
          </main>
        </Provider>
      )
    } else if (global.config.partnerPlatform == 1) {

      var GaCode = 'UA-71798345-3';
      ReactGA.initialize(GaCode);
 
      ReactGA.pageview(this.props.location.pathname);


      if (matchPath(this.props.location.pathname, { path: '/partner-signup/:slug' })) {
        return (
          <Provider store={store}>
              <main role="main" className="container-fluid g-0">
                <Switch>
                  <Route exact path="/partner-signup/:slug/" component={PartnerProgram} />
                </Switch>
              </main>
          </Provider>
        )
      } else if (matchPath(this.props.location.pathname, { path: '/create-partner-test/:signup_id/:parentTestId' })) {
          return (
            <Provider store={store}>
                <main role="main" className="container">
                  <Switch>
                    <Route exact path="/create-partner-test/:signUp_id/:parentTestId">
                      <RedirectToBespoke/>
                    </Route>
                  </Switch>
                </main>
            </Provider>
          )
      } else if (['','partner-add-client', 'partner-commission' ,'activate-client','partner-bespoke-test', 'partner-orders', 'partner-new-order', 'partner-results', 'partner-clients', 'partner-results', 'partner-account', 'partner-settings', 'partner-tests' ,'partner-add-test', 'partner-users', 'partner-add-user', 'partner-edit-user', 'partnerRedirect', 'place-order', 'activate-kit', 'stocked-kits'].includes(window.location.pathname.split('/')[1])){ 
        var containerPlatform = ['', 'partner-add-client', 'partner-commission' ,'activate-client', 'partner-bespoke-test', 'partner-orders', 'partner-new-order', 'partner-results', 'partner-clients', 'partner-results', 'partner-account', 'partner-settings', 'partner-tests' ,'partner-add-test', 'partner-users', 'partner-add-user', 'partner-edit-user', 'partnerRedirect', 'place-order', 'activate-kit', 'stocked-kits'].includes(window.location.pathname.split('/')[1]) ? true : false;      
          return (
            <Provider store={store}>
              <div className="d-block toggled" id="wrapper">
                <div id="page-content-wrapper" className={containerPlatform ? "partnerLayout" : "pt-0"}>
                  {!_.isEmpty(this.props.partner) && <PartnerHeader />}
                  <main role="main" className={containerPlatform ? "container-fluid px-0" : null} id="partnerContainer">
                      <Switch>   
                        <PrivateRoute exact path="/" component={PartnerManagement}/> 
                        <PrivateRoute exact path="/partner-account/" component={PartnerAccount}/>
                        <PrivateRoute exact path="/partnerRedirect" component={PartnerRedirect}/>
                        <RestrictedRoute exact path="/stocked-kits" component={hasAccessToShockedKits === true ? BlankKits : hasAccessToShockedKits === false ? Page404 : Loader} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessClientsOrders == 0}/>
                        <RestrictedRoute exact path="/partner-orders/:labStatus?" component={PartnerOrders} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessClientsOrders == 0}/>
                        <RestrictedRoute exact path="/partner-new-order/:clientId" component={PartnerNewOrder} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessClientsOrders == 0} />
                        <RestrictedRoute exact path="/partner-new-order/:clientId/:testId" component={PartnerNewOrderInfo} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessClientsOrders == 0} />
                        <RestrictedRoute exact path="/partner-clients" component={PartnerClients} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessClientsOrders == 0} />
                        <RestrictedRoute exact path="/partner-add-client" component={PartnerAddClient} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessClientsOrders == 0} />
                        <RestrictedRoute exact path="/partner-clients/:clientId" component={PartnerEditClient} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessClientsOrders == 0} />
                        <RestrictedRoute exact path="/partner-results" component={PartnerResults} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessResults == 0} />
                        <RestrictedRoute exact path="/partner-results/:clientId" component={PartnerClientResult} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessResults == 0} />
                        <RestrictedRoute exact path="/partner-results/:clientId/:orderId" component={PartnerClientResultDetails} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessResults == 0} />
                        <RestrictedRoute exact path="/partner-results/:userId/:orderId/:biomarkerId/:slug" component={PartnerClientBiomarkerDetails} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessResults == 0} />
                        <RestrictedRoute exact path="/partner-settings/" component={PartnerSettings} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessSettings == 0} />
                        <RestrictedRoute exact path="/partner-tests/" component={PartnerTests} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessSettings == 0} />
                        <RestrictedRoute exact path="/partner-tests/:testId" component={PartnerTestEdit} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessSettings == 0} />
                        <RestrictedRoute exact path="/partner-add-test/" component={PartnerTestAdd} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessSettings == 0} />
                        <RestrictedRoute exact path="/partner-bespoke-test/:partnerId/:parentTestId" isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessSettings == 0}>
                          <RedirectToBespoke/>
                        </RestrictedRoute>
                        <RestrictedRoute exact path="/partner-users" component={PartnerUsers} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessUserManagment == 0} />
                        <RestrictedRoute exact path="/partner-add-user/" component={PartnerAddUser} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessUserManagment == 0} />
                        <RestrictedRoute exact path="/partner-edit-user/:userId" component={PartnerEditUser} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessUserManagment == 0} />
                        <RestrictedRoute exact path="/partner-commission" component={PartnerCommision} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessFinancial == 0} />
                        <RestrictedRoute exact path="/activate-kit/:activationToken?" component={ActivationKit} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessUserManagment == 0} />
                        <RestrictedRoute exact path="/place-order/:customerId?" component={PartnerPlaceOrder} isRestricted={this.props.user.accessSuperAdmin == 0 && this.props.user.accessFinancial == 0} />
                        <Route exact path="/sign-in" component={PartnerSignIn} /> 
                        <Route path="*" component={Page404} />
                      </Switch>
                  </main>
                  {!_.isEmpty(this.props.partner) && <PartnerFooter /> }
                </div>
              </div>
            </Provider>
          )
        /* } */

      } else{
        return (
          <div className="d-block toggled partner" id="wrapper">
            <main role="main" className={`${container ? "container" : ""}`}>
              <Provider store={store}>
                <Switch>
                  <Route  component={Page404} />
                </Switch>
              </Provider>
            </main>
          </div>
        )
      }
    } else if (global.config.partnerStore == 1) {
      if (window.location.href.includes('aliness?utm_campaign=newsletter-featur&PC=OUTLIOS&FORM=OUTLSB')) {
        window.location.href = '/aliness'
      }
      if (window.location.href.includes('p/store')) {
        const code = window.location.pathname.split('/')[3]
        window.location.href = `/${code}`
      }
        return (
          <Provider store={store}>
            <div className="d-block toggled partner" id="wrapper">
              {Object.keys(this.props.partnerShop).length > 0 ? 
              <main role="main" className={`${container ? "container" : ""}`}>
                {/* {this.state.partnerHasAccepted == 1 || this.state.partnerPreview ?  */}
                  <Switch>
                    <Route exact path="/p/store/:requestCode" component={PartnerShop} /> 
                    
                    <Route exact path="/:requestCode" component={PartnerShop} /> 
                    <Route exact path="/:requestCode/how-it-works" component={PartnerStoreHowItWorks} />
                    <Route exact path="/:requestCode/partnership" component={PartnershipContainer} />
                    <Route exact path="/:requestCode/:testId" component={PartnerStoreItem} />
                    {/* <Route component={Page404} /> */}
                    <Route path='/' component={() => { 
                      window.location.href = 'https://app.biomarkertracking.com'; 
                      return null;
                    }}/>
                  </Switch>
                {/* : 
                  <Switch>
                    <Route component={Page404} />
                  </Switch> 
                } */}
              </main>
            :<Loader />}
            </div>
          </Provider>
        )
    } else { 
        return (
          <Provider store={store}>
            <div className="d-block toggled" id="wrapper">
              <Sidebar />
              <div id="page-content-wrapper"> 
              {!['partner-management', 'partner-orders', 'partner-results', 'partner-clients', 'bespoke-test', 'checkout', 'checkout-new', 'checkout-from-partner', 'checkout-success', 'sign-in', 'edit-test', 'program', 'partner-add-client', ].includes(window.location.pathname.split('/')[1]) && window.location.pathname != '/chat'  && (
                <Header />
              )} 
              <Route exact path="/(category|results)/:orderId"  component={SubNavigation} />
              <Route exact path="/category/:orderId/:categoryType/:slug"  component={SubNavigation} />
              {/* <Route exact path="/result/:orderId/:biomarkerId/:slug"  component={SubNavigation} /> */}
              <main role="main" className={`${container ? "container" : ""}`}>
                <Switch> 
                  <Route exact path="/checkout/" component={Checkout} />  
                  {/* <Route exact path="/checkout/:basket/:voucherCode?" component={CheckoutFromWebsite} /> */}
                  <Route exact path="/checkout/:basket/:voucherCode?" component={Checkout} />
                  <Route exact path="/checkout-from-partner/:partnerParam" component={CheckoutFromPartner} />
                  <Route exact path="/checkout-success/:payload" component={CheckoutKlarnaSuccess} />
                  <Route exact path="/add-to-basket/:basket/:voucherCode?" component={CheckoutFromWebsite} /> 
                  <Route exact path="/bespoke-test/:parentTestId/:testType?/:preData?/:chatTest?/:voucherCode?/:partnerId?/:checkoutFromPartnerId?" >
                    <RedirectToBespoke/>
                  </Route>
                  <Route exact path="/edit-test/:editTestId?/:phlebotomyType?/:voucherCode?">
                    <RedirectToBespoke/> 
                  </Route>
                  
                  <Route component={Page404} />
                </Switch>
              </main>
              {!['partner-bespoke-test', 'partner-management', 'partner-orders', 'partner-results', 'partner-clients', 'partner-account', 'partner-settings', 'partner-tests', 'partner-add-test', 'partner-users', 'partner-add-user', 'partner-edit-user', 'bespoke-test', 'checkout', 'checkout-new', 'checkout-success', 'checkout-from-partner' ,'sign-in', 'edit-test', 'program'].includes(window.location.pathname.split('/')[1]) && window.location.pathname != '/chat' ? <Footer /> : null} 
        
              </div>
 
            </div>

          </Provider>
        )

      

    }
  }
}



const mapStateToProps = (state, ownProps) => {
  const {
    authentication,
    user,
    phlebotomyPrices,
    kitPrices,
    entities: {
      partnerShop,
      partner
    }
  } = state

  return {
    authentication,
    user,
    phlebotomyPrices,
    kitPrices,
    partnerShop,
    partner
  }
}

export default withRouter(connect(mapStateToProps, {
  loadUser,
  loadPhlebotomyPrices,
  setPhlebotomyPrices,
  loadKitPrices,
  setKitPrices,
  loadValidate,
  logout,
  getPartnerUserDetails,
  loadPartnerShopDetails,
  loadPartnerUser,
  loadPartnerDetails,
  loadReferCodeDetails,
  setReferCodeDetails,
  loadCheckoutSettings,
  setDeliveryOptions,
  getDeliveryOptions
})(Root))
