import React, {useState, useEffect} from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect, useSelector } from "react-redux";
import styles from './CheckoutSuccess.module.scss'
import RenderIf from '../../../components/RenderIf/RenderIf';
import { Button } from '../../'; 
import { updateCustomerReminder } from '../../../actions/users-actions'
import {SidebarModal, LoadingSpinner} from '../../../components/' 

import ReferAFriendBanner from '../../Global/ReferAFriendBanner/ReferAFriendBanner';

const CheckoutSuccess = (props) => { 
  const [isLoading, setIsLoading] = useState(false); 
  const [reminderSent, setReminderSent] = useState(false); 
  const userDetails = useSelector(state => state.user || {})
  const customerReferCode = userDetails?.referCode || ""

  const sendReminder = () => {
    setIsLoading(true);
    let payload = {
      customerId: props.customerDetails.userId,
      websiteId: global.config.websiteId
    } 
    props.updateCustomerReminder(payload).then(function(response) {
      setIsLoading(false);
      setReminderSent(true);
    });
  } 

  let fromPartnerPortal = localStorage.getItem("FromPartnerPortal")
  if (!fromPartnerPortal){
    fromPartnerPortal = 0
  }

  let url;
  let websiteUrl;
  let companyName = 'Forth';
  let shareUrl = `https://www.forthwithlife.co.uk/?voucherCode=${customerReferCode}`;
  let showSocialShareBtn = true
  let showReferCodeBanner = true
  if (window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'appdev.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk') {
    url = 'https://app.forthwithlife.co.uk'; 
    shareUrl = `https://www.forthwithlife.co.uk/?voucherCode=${customerReferCode}`
    websiteUrl = "https://www.forthwithlife.co.uk"
  } else if (window.location.hostname == 'app.forthedge.co.uk' || window.location.hostname == 'shop.forthedge.co.uk' || window.location.hostname == 'shop.sportsbloodtests.co.uk' || window.location.hostname == 'app.sportsbloodtests.co.uk') {
    url = 'https://app.sportsbloodtests.co.uk' 
    companyName = 'Edge'
    showReferCodeBanner = false;
    shareUrl = `https://www.sportsbloodtests.co.uk/?voucherCode=${customerReferCode}`
    websiteUrl = "https://www.sportsbloodtests.co.uk"
  } else if(fromPartnerPortal){
    url = 'https://app.forthpartners.co.uk'
    showReferCodeBanner = false
    websiteUrl = "https://www.forthpartners.co.uk"
  } else {
    url = 'https://app.biomarkertracking.com' 
    showSocialShareBtn = false
    showReferCodeBanner = false
    websiteUrl = "https://www.forthwithlife.co.uk"
  }

  const shareTitle = `Referral code for ${companyName} - ${customerReferCode}`;
  const shareDescription = `I just ordered a blood test from ${companyName} and thought of you.\n\nUse my refer-a-friend code below to get £10 off your order:\n\n${customerReferCode}\n\n`;
  const shareEmailBody = `Hey there,\n\nI just ordered a blood test from ${companyName} and thought of you!\n\nUse my refer-a-friend code below to get £10 off your order:\n\n${customerReferCode}\n\n`;

  const sendCloseIframe = (e) => {
    e.preventDefault();
    if(window.parent) {
      window.parent.postMessage('closeIframe', '*');
    }
  }

  return (
    <section className={`${styles.container} d-xl-flex pb-0 pb-lg-5`}>
      
  
      <SidebarModal  modalId={"sendReminder"}>
        <div className="text-center">
          <h2>Send a reminder to update your profile?</h2>
      
          <p>It is important to ensure your profile is up to date and accurate before you sample reaches the lab. If you are unable to complete your profile at this time, we can send you an SMS reminder in 3 days.</p>
          <RenderIf isTrue={!reminderSent && !isLoading}>
            <Button onClick={() => { sendReminder() }} variant="primary" className="w-100 mt-4">
              Send Reminder
            </Button>
            <Button variant="link" className="mt-4 mx-auto" data-bs-dismiss="modal">Cancel</Button>
          </RenderIf>

          <RenderIf isTrue={isLoading}>
            <div className="d-flex justify-content-center mt-4">
              <LoadingSpinner/>
            </div>
          </RenderIf>

          <RenderIf isTrue={reminderSent}>
            <div className="alert alert-success mt-4 d-flex align-items-center">
              <i className="far fa-check-circle"></i>
              <p className="mb-0">Success! A reminder will be sent to you in 3 days.</p>
            </div>
            <Button variant="link" className="mx-auto" data-bs-dismiss="modal">Close</Button>
          </RenderIf> 

        </div>
        
      </SidebarModal>

      <RenderIf isTrue={props.paymentSuccess}>
        <header className={`${styles.header} pb-5 d-block d-lg-none text-center`}>
          <i className="fal fa-check-circle fa-fw fa-2x"></i>
          <h1 className='mt-2'>Thank you!</h1>
          <h4 className="mb-4 fw-normal">We've received your order, and your kit will be dispatched shortly</h4>
          {global.config.websiteId == 1 
            ?  <img src="/img/newDashboard.png" alt="Forth app" />
            : <img src="/img/FE-App-Glow.png" alt="Forth app" />
          }
        </header>
      </RenderIf>

      <RenderIf isTrue={!props.paymentSuccess}>
        <header className={`${styles.header} ${styles.failure} py-5 d-block d-lg-none text-center`}>
          <i className="fal fa-times-circle fa-fw fa-2x"></i> 
          <h4 className="my-4 fw-normal">Oh no we have experienced an error whilst trying to
                        process your order. Please return to your cart and try
                        again making sure all information has been completed
                        correctly.</h4>
          <Button variant="default" className="w-100 mb-4" href="/checkout/">Return to basket</Button> 
        </header>
      </RenderIf>
       

      <main className="container px-2 py-0 py-lg-5">
        <div className="row">
          <RenderIf isTrue={props.paymentSuccess}>
            <div className="col-12 col-lg-6">
              <div className={`${styles.blueArea} ${styles.box} ${styles.failure} h-100 d-none d-lg-flex flex-column align-items-center justify-content-center text-center px-5`}>
                <i className="fal fa-check-circle fa-fw fa-3x"></i>
                <h1 className="mt-4 fw-semibold px-3">Thank you!</h1>
                <p className="mb-4 fw-normal px-1">We've received your order, and your kit will be dispatched shortly.</p>
                
                <RenderIf isTrue={!props.guestCheckout}>
                  <div className="my-4 mb-5">
                    <div className={`text-center px-lg-2`}>
                      <h2>Here's what to do next:</h2>
                      <p className="px-2">Answer your <strong>health questionnaire</strong> to ensure accurate results:</p>

                      <div className="row align-items-between">
                        <div className="col-6 text-center">
                          <Button href={url + '/my-account'} target="_blank" variant="primary" className="w-100 h-100 d-block">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              <div>Answer now</div>
                            </div>
                          </Button>
                        </div>
                        <div className="col-6">
                          <Button data-bs-toggle="modal" data-bs-target="#sendReminder" variant="primary-outline" className="w-100 h-100 d-block">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              Remind me later
                            </div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <RenderIf isTrue={showReferCodeBanner}>
                    <div className='mb-4'>
                      <ReferAFriendBanner 
                        url={shareUrl}
                        title={shareTitle}
                        description={shareDescription}
                        emailBody={shareEmailBody}
                        voucherCode={customerReferCode}
                        showSocialShareBtn={showSocialShareBtn}
                      />
                    </div>
                  </RenderIf>
                </RenderIf>

                { window !== window.parent ? (
                  <Button onClick={(e) => sendCloseIframe(e) } variant="link" className="w-100 d-none d-md-block text-center ">
                    Continue shopping
                  </Button>
                ) : fromPartnerPortal == 1 ? (
                  <Button href={url} variant="link" className="w-100 d-none d-md-block text-center ">
                    Return to partner dashboard
                  </Button>
                ) : (
                  <Button href={websiteUrl} variant="link" className="w-100 d-none d-md-block text-center ">
                    Continue shopping
                  </Button>
                )}
                 
              </div>
            </div>
          </RenderIf>

          <RenderIf isTrue={!props.paymentSuccess}>
            <div className="col-12">
              <div className={`${styles.blueArea} h-100 d-none d-lg-flex flex-column align-items-center justify-content-center text-center py-5 px-5`}>
                <i className="fal fa-times-circle fa-fw fa-3x"></i>
                <h3 className="my-4 fw-normal px-3">Oh no we have experienced an error whilst trying to
                        process your order. Please return to your cart and try
                        again making sure all information has been completed
                        correctly.</h3>
                <Button variant="primary-outline" className="w-100 mb-4" href="/checkout/">Return to basket</Button> 
              </div>
            </div>
          </RenderIf>

          <RenderIf isTrue={props.paymentSuccess}>
            <div className="col-12 col-lg-6">
              <div className="row justify-content-center mb-2 ">
                <RenderIf isTrue={props.guestCheckout}>
                  <div className="col-12 col-lg-9">
                    <div className={`${styles.box} text-center px-lg-5`}>
                      <h3>Your test is on it's way</h3>
                      <p className="px-2 mb-1">Your test will contain information on how to create your account and activate your kit.</p>
                    </div>
                  </div>
                </RenderIf>
                <RenderIf isTrue={!props.guestCheckout}>
                  <div className="col-12 col-lg-9">

                    <div className={`${styles.box} d-block d-lg-none text-center px-lg-5`}>
                      <h3>Prepare for your test next steps</h3>
                      <p className="px-2">Answer your health questions to ensure accurate results.</p>

                      <Button href={url + '/my-account'} target="_blank" variant="primary" className="w-100 mb-4 d-block">
                        Answer now
                      </Button>
    
                      <Button  data-bs-toggle="modal" data-bs-target="#sendReminder"  variant="primary-outline" className="w-100">
                        Remind me later
                      </Button>
                    </div>

                    <RenderIf isTrue={showReferCodeBanner}>
                      <div className='d-block d-lg-none my-4'>
                        <ReferAFriendBanner 
                          url={shareUrl}
                          title={shareTitle}
                          description={shareDescription}
                          emailBody={shareEmailBody}
                          voucherCode={customerReferCode}
                          showSocialShareBtn={showSocialShareBtn}
                        />
                      </div>
                    </RenderIf>
                  </div>
                </RenderIf>
              </div>

              <div className="d-block d-md-none row justify-content-center mb-4 mt-4">
                <div className="col-12 col-lg-9">
                  

                  { window !== window.parent ? ( 
                    <Button onClick={(e) => sendCloseIframe(e) } href={url} variant="link" className="w-100 d-block text-center ">
                      Continue shopping
                    </Button>
                  ) : (
                    <Button href={websiteUrl} variant="link" className="w-100 d-block text-center ">
                      Continue shopping
                    </Button>
                  )}
                </div>
              </div>

              <div className="row justify-content-center d-none d-lg-flex ">
                <div className="col-12">
                   
                  {global.config.websiteId == 1 
                    ? <img src="/img/newDashboard.png" alt="Forth app" className={`${styles.imgLargeScreen} text-center mx-auto d-none d-lg-block`} />
                    : <img src="/img/FE-App-Glow.png" alt="Forth app" className={`${styles.imgLargeScreen} text-center mx-auto d-none d-lg-block`} />
                  }
                </div>
                <div className="col-12 col-lg-9">
                  <div className={`${styles.appSection} mt-4 text-center`}>
                    <p>Download our app to view your results</p>
                    <div className="row g-2 justify-content-center">
                      <div className="col-auto">
                        <a href="https://apps.apple.com/gb/app/forth-edge/id1468480957">
                          <img src="/img/iOSAppStoreM.png" alt="Forth IOS app" className={styles.ios}/>
                        </a>
                      </div>
                      <div className="col-auto">
                        <a href="https://play.google.com/store/apps/details?id=com.forthedge">
                          <img src="/img/androidAppStoreM.png" alt="Forth android app" className={styles.android}/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </RenderIf>
        </div>
        
      </main>
      <RenderIf isTrue={props.paymentSuccess}>
        <div className={`${styles.appSection} d-block d-lg-none mt-0 pb-5 mt-md-5 text-center`}>
          <p>Download our app to view your results</p>
          <div className="row g-2 justify-content-center">
            <div className="col-auto">
              <a href="https://apps.apple.com/gb/app/forth-edge/id1468480957">
                <img src="/img/iOSAppStoreM.png" alt="Forth IOS app" className={styles.ios}/>
              </a>
            </div>
            <div className="col-auto">
              <a href="https://play.google.com/store/apps/details?id=com.forthedge">
                <img src="/img/androidAppStoreM.png" alt="Forth android app" className={styles.android}/>
              </a>
            </div>
          </div>
        </div>
        <RenderIf isTrue={!["localhost", "appdev.forthwithlife.co.uk", "appdev.forthedge.co.uk", "appdev.sportsbloodtests.co.uk", "appdev.forthpartners.co.uk"].includes(window.location.hostname)}>
          <img src={`https://a.forthwithlife.co.uk/sale.php?profile=72198&idev_saleamt=${props?.totalAmount}&idev_ordernum=${props?.orderId}`} style={{height: "0px", width: "0px", border: "0px"}} />
        </RenderIf>
      </RenderIf>
    </section>
  );
}

const mapStateToProps = (state, ownProps) => {
  
  const {
    basket,
    authentication, 
    user
  } = state
 
  return {
    authentication, 
    user,
    basket
  }
}

export default withRouter(connect(mapStateToProps, { 
  updateCustomerReminder,
})(CheckoutSuccess))