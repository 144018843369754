import React from 'react'
import RenderIf from '../../RenderIf/RenderIf';
import styles from './Alert.module.scss'

export default function Alert({children, variant = "primary", icon = "fas fa-info-circle", className}) {
  return (
    children ? (
      <div className={`alert alert-${variant} ${className}`} role="alert">
        <RenderIf isTrue={icon}>
          <i className={icon}></i>
        </RenderIf>
        {children}
      </div>
    ) : null
  );
}