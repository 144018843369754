import React, {useState, useEffect} from 'react'
import styles from "./HowToPhlebotomy.module.scss"; 

export default function HowToPhlebotomy(props) {   
 
  return ( 
    <div className="row">
      <div className="col-12 text-center">
        {global.config.websiteId == 1 
          ? <img className="mb-4 mt-4 modalIcon" src="/img/forthGradientIcon.png"/>
          : <img className={`${styles.logo} mb-4 mt-5 modalIcon`} src="/img/FE-Droplet-Glow.png" alt="forth edge logo" />
        }
        <h1 className="mb-5">I need a professional to draw my blood</h1>
      </div>
   
      <div className="col-12 text-center">
        <p>We provide you with a FREE blood draw kit so you can arrange a professional to draw your sample.</p>
        <p>Alternatively you can choose to have one of our nationwide phlebotomists visit your home or office at a convenient time for a fee of <strong>£{parseFloat(props.phlebotomyPrices[2].phlebotomyPrice)}</strong> or visit one of nationwide partner clinics for <strong>£{parseFloat(props.phlebotomyPrices[5].phlebotomyPrice)}</strong>.</p>
      </div>
      <div className={`col-12 text-center mb-5 position-relative ${styles.isolate}`}>
        {global.config.websiteId == 1 
          ? <img id="phlebotomyNurse" src="/img/phlebotomyNurse.png" alt="nurse picture" width="75%" className="mt-4"/>
          : (
              <>
                <div className={styles.nurseBg}></div>
                {global.config.websiteId == 1 
                  ? <img id="phlebotomyNurse" src="/img/nurse.png" className={`${styles.nurse} mt-4 px-5`} alt="nurse picture" />
                  : <img id="phlebotomyNurse" src="/img/FE-Nurse-Glow.png" className={`${styles.nurse} mt-4 px-5`} alt="nurse picture" />
                }
              </>
        )}
      </div>
    </div>
  )
}
