import React, {useState, useEffect, useMemo} from 'react' 
import { Button, GrantPartnerPermission, LoginForm } from '../../../components/'
import { LoadingSpinner } from '../../../components/'
import { getPartnerPortalId } from '../../../utils';
import RenderIf from '../../RenderIf/RenderIf';
import styles from "./UserDetails.module.scss";
import runGA from '../../../helpers/runGA';
import formValidator from '../../../helpers/formValidator'; 
import base64 from 'base-64' 


 
const INITIAL_HRT_SELECTED_VALUE = {
  3: false,
  4: false,
  5: false
}

const HRToptions = [
  {value: 3, text: "HRT - Gel for oestradiol"},
  {value: 4, text: "HRT - Patch"},
  {value: 5, text: "HRT - Tablet"}
]

export default function UserDetails(props) { 

  useEffect(() => { 
    if (props.guestCheckout) {
      props.setCanEnterDetails(true)
    }
  },[]);

  const user = props.user;
  const partner = props.partner;
  const authentication = props.authentication; 
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(0)
  const [newAccount, setNewAccount] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  let stateVoucher = localStorage.getItem('voucher');

  let voucher = props.voucher;
  if (!voucher) {
    voucher = JSON.parse(stateVoucher); 
  }  
 
  let guestCheckout = props.guestCheckout;

  if (props.guestCheckout == 'unset') {
    guestCheckout = false;
  }
 
  let partnerPortalId = getPartnerPortalId(partner)
 
  const submitUserForm = (e) => {

    e.preventDefault(); 
    setError(false);
    setFormSubmitted(formSubmitted+1);
    if (!props.checkoutFromPartner && document.getElementById("customerEmailAddress").value != '' && formValidator(document.getElementById("customerEmailAddress").value, 'email') == true) {
      const hasNumber = /\d/; 
      const hasSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      setErrorMessage(""); 

      if((props.authentication.isAuthenticated != true && (guestCheckout == false || guestCheckout == 'unset')) && props.checkoutFromPartner == 0){
        if(props.customerDetails.customerPassword != "" && hasNumber.test(props.customerDetails.customerPassword) && hasSpecial.test(props.customerDetails.customerPassword) && props.customerDetails.customerPassword?.length >= 8){
          setError(false)
          setErrorMessage('')
          //return
        } else {
          setError(true);
          setErrorMessage("Your password must contain at least 1 number and 1 special character, and at least eight characters")  
          //return
        }
      }

      if(!props.customerDetails?.doNotReview && props.customerDetails?.doNotReview == undefined){
        setError(true);
        setErrorMessage("Please, enter if you would like a Forth doctor comment on you results.")
        return  
      }

      if(!props.customerDetails?.customerFirstName){
        setError(true);
        setErrorMessage("Please, enter your first name")  
        return
      }

      if(!props.customerDetails?.customerLastName){
        setError(true);
        setErrorMessage("Please, enter your last name")  
        return
      }

      if (!guestCheckout) {

        if (props.customerDetails.customerSex == 2) {
          if (props.customerDetails.customerMenopausal == 0 || props.customerDetails.customerMenopausal == "0" || props.customerDetails.customerMenopausal == "") {
            setError(true);
            setErrorMessage("Please tell us if you describe yourself as post-menopausal")  
            return
          }

          if (props.customerDetails.customerMenopausal == 2) {
            if(props.customerDetails.customerHRT == 0 || props.customerDetails.customerHRT == "0" || props.customerDetails.customerHRT == "") {
              setError(true);
              setErrorMessage("Please tell is if you are on HRT")  
              return
            }

            if(props.customerDetails.customerHRT == 2){
              const hasHRTSelected = Object.values(props.customerDetails.customerHRTSelected).some( item => item == true)
              if(!hasHRTSelected){
                setError(true);
                setErrorMessage("Please enter what type of HRT you are")  
                return
              }
            }
          }

          if (props.customerDetails.customerMenopausal == 1) {
            if (props.customerDetails.customerContraception == 0 || props.customerDetails.customerContraception == "0" || props.customerDetails.customerContraception == "") {
              setError(true);
              setErrorMessage("Please tell us if you are using contraception")  
              return
            }
            if (props.customerDetails.customerContraception == 1) {
              if (props.customerDetails.customerRegularPeriods == 0 || props.customerDetails.customerContraception == "0" || props.customerDetails.customerContraception == "") {
                setError(true);
                setErrorMessage("Please tell us if you are having regular periods") 
                return
              }
            }
            if (props.customerDetails.customerContraception == 2 && !props.customerDetails.customerContraception){
              setError(true);
              setErrorMessage("Please tell us what form of hormonal contraception you are taking") 
              return
            }
          }
        }

        if(!props.customerDetails.customerEthnicity || props.customerDetails.customerEthnicity == 0){
          setError(true);
          if(props.checkoutFromPartner == 1){
            setErrorMessage("Please enter your ethnicity")  
          }else{
            setErrorMessage("Please enter your client's ethnicity")  
          }
          return
        }

      } else {
        // Do guest stuff here

        let payload = {
          customerEmailAddress: document.getElementById("customerEmailAddress").value,
          customerPassword:
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15),
          orderCookieId: localStorage.getItem("orderCookieId"),
          guestCheckout: true,
        };
  
        props.loadCreateAccount(payload).then((response) => {
          if (response.error) { 
            setError(true);
            setErrorMessage(response.error); 
            props.setCanEnterDetails(false);  
          } else {  
            if (!error) {
              
              setError(false);
              setErrorMessage(''); 
              props.setCanEnterDetails(true);
              props.setGuestCustomerId(response.response.newCustomerId)
              props.setCustomerDetails({
                customerEmailAddress:  document.getElementById("customerEmailAddress").value,
                guestCustomerId: response.response.newCustomerId,
                ...props.customerDetails
              })    
              let cart = props.cart[0].basketArray;
              let newCart = [];
              Object.keys(cart).map(function(key) {
                newCart.push(cart[key][0] + ":0");
              });
  
              let newUrl = base64.encode(newCart.toString());
  
              let items = props.basketTestItems;
              let itemArray = [];
              Object.keys(items).map(function(key) {
                itemArray.push(items[key]);
              });
  
              if (global.config.websiteId == 1 || global.config.websiteId == 2) {
                let website = "shop.sportsbloodtests.co.uk";
                if (global.config.websiteId == 1) {
                  website = "shop.forthwithlife.co.uk";
                }
                //SEND IN BLUE TRACKING TO GO HERE
              }
            }
          }
        });
      }

    } else if (!props.checkoutFromPartner){
      setError(true); 
      setErrorMessage("Please enter a valid email address")  
    }
  }

  const handleInputErrors = (fieldName, isRequired) => {

    if (props.isFirstSubmit > 0) {
      const value = props.customerDetails[fieldName]
      let helperClassName= ""
   
      if (!fieldName && !isRequired) {
        return {
          icon: <></>,
          color: "",
        };
      }

      if(["customerDOBD", "customerDOBM", "customerDOBY"].includes(fieldName)){
        helperClassName = styles.alignCenter
      } 

      if(fieldName == "doNotReview" && value == undefined){
        return {
          icon: <i className={`fas fa-times-circle fa-fw ${helperClassName}`}></i>,
          color: "red",
        }
      }
      
      if(value == undefined){
        return {
          icon: <></>,
          color: "",
        };
      }


      if((value == "" || value == "0") && isRequired && fieldName != "doNotReview") {
        return {
          icon: <i className={`fas fa-times-circle fa-fw ${helperClassName}`}></i>,
          color: "red",
        }
      }

      if(["customerFirstName", "customerLastName"].includes(fieldName) && value <= 2) {
        return {
          icon: <i className="fas fa-times-circle fa-fw"></i>,
          color: "red",
        }
      }

      if(fieldName == "customerHRTSelected" && props.customerDetails["customerHRT"] == 2 && !Object.values(props.customerDetails["customerHRTSelected"]).some( item => item == true)){
        return {
          icon: <i className="fas fa-times-circle fa-fw"></i>,
          color: "red",
        }
      }

      return {
        icon: <i className={`fas fa-check-circle ${helperClassName}`}></i>,
        color: "green",
      }
    } else {
      return {
        icon: "",
        color: "",
      }
    }
    
  }

  const handleChangeInputs = ({target}, externalValue) => {
    const {name, value} = target

    if(externalValue != undefined){
      const name = target.getAttribute("name")

      props.setCustomerDetails({
        ...props.customerDetails,
        [name]: externalValue   
      })
    } else {
      props.setCustomerDetails({
        ...props.customerDetails,
        [name]: value
      })
    }

     

    if(name == "customerSex"){
      if (value == 1) {
        props.setCustomerDetails({
          ...props.customerDetails,
          [name]: value,
          customerHRT: 0,
          customerHRTSelected: INITIAL_HRT_SELECTED_VALUE,
          customerAverageCycleLengthRef: 0,
          customerMenopausal: 0,
          customerRegularPeriods: 0,
          customerContraception: 0,
          customerContraceptionSelected: 0,
          customerAverageCycleLength: "",
        })
         
      } else if (value == 2) {
        props.setCustomerDetails({
          ...props.customerDetails,
          [name]: value
        })
      }
    }
  }

  const handleCheckboxChange = (userField) => {
    let customerOptIn = props.customerDetails.customerOptIn;
    let doNotReview = props.customerDetails.doNotReview == 0 ? false : true;

    if (userField == 'customerOptIn') {
      props.setCustomerDetails({
        ...props.customerDetails,  
        customerOptIn: !customerOptIn
      })
    }
    
    if (userField == 'doNotReview') {
      props.setCustomerDetails({
        ...props.customerDetails,  
        doNotReview: !doNotReview
      })
    }   
  }
  

  const yearArray = () => {
    let yearArray = [];
    let currentYear = new Date().getFullYear() - 18;
    let years = [];
    let year;
    let startYear = 1900;
    for(let i=currentYear; i > startYear; i--){
      year = i;
      yearArray.push(<option value={year}>{year}</option>)
    }
    return yearArray;
  }

  const handleChangeHRTSelected = (id) => { 
    props.setCustomerDetails({
      ...props.customerDetails,
      customerHRTSelected: {
        ...props.customerDetails.customerHRTSelected,
        [id]: !props.customerDetails.customerHRTSelected[id]
      }
    })
  }

  const handleGrantPartnerPermission = () => {  
    props.setPermissionChanged(1)
    props.setGrantPartnerPermission(1)
    props.setCustomerDetails({
      ...props.customerDetails, 
      grantPartnerPermission: 1
    })
  }

  return ( 
    <div className="row pt-5 pb-4">
      <div className="col-12 text-center">
        {props.guestCheckout ? <h2>Details of who to send the kit to</h2> : <h2>Details we need to prepare your test</h2>}
      </div>
      {!props.guestCheckout && <div className="col-12 mt-4">
        <p className={styles.explainer}>
          <i className="far fa-info-circle"></i>  You need to set up an account in order to receive your results via your secure dashboard.
        </p>
      </div> }
      <div className="col-12">
        <RenderIf isTrue={props.checkoutFromPartner == 0}>
          <LoginForm 
            customerDetails={props.customerDetails}
            setCustomerDetails={props.setCustomerDetails}
            canEnterDetails={props.canEnterDetails}
            setCanEnterDetails={props.setCanEnterDetails}
            guestCheckout={props.guestCheckout}
            loadToken={props.loadToken}
            loadUser={props.loadUser}
            reloadUserData={props.reloadUserData}
            loadPartnerDetailsUnsecure={props.loadPartnerDetailsUnsecure}
            setGuestCustomerId={props.setGuestCustomerId}
            cart={props.cart}
            basketTestItems={props.basketTestItems}
            authentication={props.authentication}
            loadCreateAccount={props.loadCreateAccount}
            checkEmailExist={props.checkEmailExist}
            newAccount={newAccount}
            setNewAccount={setNewAccount}
            setPartner={props.setPartner}
            partner={props.partner}
            setReceiptEmailAddress={props.setReceiptEmailAddress}
            generateReferCode={props.generateReferCode}
          />
        </RenderIf>

{/*       {props.guestCheckout && <div className="col-12 mt-4">
        <div className={styles.explainer}>
          We need an email address to send proof of purchase to
        </div>
      </div>} */}

        
        <RenderIf isTrue={props.isLoadingSmall}>
          <div className="row justify-content-center">
            <div className="col-auto">
              <LoadingSpinner/>
            </div>
          </div>
        </RenderIf>

        <RenderIf isTrue={props.canEnterDetails && (props.guestCheckout || props.authentication.isAuthenticated || newAccount)}>
          <form onSubmit={(e) => { submitUserForm(e)}}  className={styles.userForm} data-bs-toggle={props.canEnterDetails ? "" : "modal"} data-bs-target={"#userDetailsAlert"}>
            {(props.guestCheckout == false || props.guestCheckout == "unset") && (
              <div className="row">

                <div className="form-group col-6">
                  <label className="position-relative" htmlFor="customerFirstName">
                    <p className="fw-bold mb-2">* First name:</p>
                    <input disabled={props.canEnterDetails ? false : true} helpercolor={handleInputErrors("customerFirstName", true).color} required onChange={handleChangeInputs} value={props.customerDetails.customerFirstName} type="text" className="form-control required py-2" id="customerFirstName" name="customerFirstName" placeholder="First name" />
                    {handleInputErrors("customerFirstName", true).icon}
                  </label>
                </div>
                        
                <div className="form-group col-6">
                  <label className="position-relative" htmlFor="customerLastName">
                    <p className="fw-bold mb-2">* Surname:</p>
                    <input disabled={props.canEnterDetails ? false : true} helpercolor={handleInputErrors("customerLastName", true).color} required onChange={handleChangeInputs} value={props.customerDetails.customerLastName} type="text" className="form-control required py-2" id="customerLastName" name="customerLastName" placeholder="Surname"/>
                    {handleInputErrors("customerLastName", true).icon}
                  </label>
                </div> 

                <div className="form-group col-12"> 
                  <label className="position-relative" htmlFor="customerHomeTelephone">
                    <p className="fw-bold mb-2">* Telephone:</p>
                    <input disabled={props.canEnterDetails ? false : true} type="number" helpercolor={handleInputErrors("customerHomeTelephone", true).color} required onChange={handleChangeInputs} value={props.customerDetails.customerHomeTelephone} className="form-control required py-2" id="customerHomeTelephone" name="customerHomeTelephone" placeholder="Telephone number" />
                    {handleInputErrors("customerHomeTelephone", true).icon}
                  </label>
                  <div className="mt-2">
                    <small>We need a phone number in case of a medical emergency</small>
                  </div>
                </div>

                <div className="form-group col-12">
                  <label className="position-relative" htmlFor="customerDOBD">
                    <p className="fw-bold mb-2">* Date of birth <span className="fw-normal">(You must be 18+ to order a test)</span>:</p> 
                  
                    <div className="row">
                      <div className="col-3 position-relative">
                        <select disabled={props.canEnterDetails ? false : true} onChange={handleChangeInputs} helpercolor={handleInputErrors("customerDOBD", true).color} value={props.customerDetails.customerDOBD} className="form-control required py-2" id="customerDOBD" name="customerDOBD" required>
                          <option hidden="true">Day</option>
                          <option disabled="disabled" defaultValue="" default="true">Day</option>
                          {Array.from(Array(31).keys()).map( index => {
                            const day = (index + 1).toString().padStart(2, '0')
                            return (
                              <option value={day} key={day}>{day}</option>
                            )
                          })}
                        </select>
                        {handleInputErrors("customerDOBD", true).icon}
                      </div>

                      <div className="col-5 position-relative">
                        <select disabled={props.canEnterDetails ? false : true} onChange={handleChangeInputs} helpercolor={handleInputErrors("customerDOBM", true).color} value={props.customerDetails.customerDOBM} className="form-control required py-2" id="customerDOBM" name="customerDOBM" required>
                          <option selected="selected" disabled defaultValue="">Month</option>
                          <option hidden="true">Month</option> 
                          <option value="01">January</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        {handleInputErrors("customerDOBM", true).icon}
                      </div>


                      <div className="col-4 position-relative">
                        <select disabled={props.canEnterDetails ? false : true} onChange={handleChangeInputs} helpercolor={handleInputErrors("customerDOBY", true).color} value={(props.customerDetails.customerDOBY)} className="form-control required py-2" id="customerDOBY" name="customerDOBY" required>
                          <option hidden="true">Year</option>
                          <option disabled="disabled" default="true">Year</option>
                          {yearArray()}
                        </select>
                        {handleInputErrors("customerDOBY", true).icon}
                      </div>
                    </div>
                  </label>
                  <div className="mt-2">
                    <small>We ask this question to be able to apply the appropriate age matched range</small>
                  </div>
                </div>

                <div className="form-group col-12 col-md-12 pt-3"> 
                  <label className="position-relative" htmlFor="customerEthnicity">
                    <p className="fw-bold mb-2">{props.checkoutFromPartner ? "How would you describe your client's ethnicity?" : '* How would you describe your ethnicity?'}</p>
                    <select disabled={props.canEnterDetails ? false : true} onChange={handleChangeInputs} value={props.customerDetails.customerEthnicity} helpercolor={handleInputErrors("customerEthnicity", true).color} className="form-control required py-2" id="customerEthnicity" name="customerEthnicity" required>
                      <option hidden="true">Ethnicity</option>
                      <option disabled="disabled" defaultValue="" default="true">Ethnicity</option>
                      <option value="1">White</option>
                      <option value="2">Asian (Indian, Pakistani, Bangladeshi)</option>
                      <option value="3">Asian (Chinese)</option>
                      <option value="4">Black (African, Caribbean, other)</option>
                      <option value="5">Arab</option>
                      <option value="6">Mixed or multiple ethnic groups</option>
                      <option value="7">Other ethnic group</option>
                      <option value="8">I'd rather not say</option>
                    </select> 
                    {handleInputErrors("customerEthnicity", true).icon}
                  </label>
                
                  <div className="mt-2">
                    <small>We ask this question as certain ethnic groups have a greater risk of disease</small>
                  </div>
                </div>

                <div className="form-group col-12 col-md-12"> 
                  <label className="position-relative" htmlFor="customerSex">
                    <p className="fw-bold mb-2">* Biological sex at birth:</p>
                    <select disabled={props.canEnterDetails ? false : true} required onChange={handleChangeInputs} helpercolor={handleInputErrors("customerSex", true).color} value={props.customerDetails.customerSex} className="form-control required py-2" id="customerSex" name="customerSex">
                      <option value="" hidden="true">Biological sex at birth</option>
                      <option disabled="disabled" default="true" defaultValue="">Biological sex at birth</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                    {handleInputErrors("customerSex", true).icon} 
                  </label>
                  <div className="mt-2">
                    <small>We ask this question to be able to apply the appropriate sex matched range</small>
                  </div>
                </div>

                {props.customerDetails.customerSex == 2 && (
                  <>
                    <div className="form-group col-12">
                      <label className="position-relative" htmlFor="customerMenopausal" helpercolor={handleInputErrors("customerMenopausal", true).color}>
                        <b>Would you describe yourself as post-menopausal?</b> {handleInputErrors("customerMenopausal", true).icon}
                      </label>
                    
                      <div className="row">
                        <div className="col-6 position-relative">
                          <div className={`${props.customerDetails.customerMenopausal == 2 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerMenopausal" onClick={(e) => handleChangeInputs(e, 2)}> 
                            <div className="d-flex align-items-center justify-content-between" name="customerMenopausal">
                              <div className="d-flex align-items-center justify-content-between" name="customerMenopausal">
                                <div className={"me-3 "+styles.checkBoxCircle} name="customerMenopausal">
                                  {props.customerDetails.customerMenopausal == 2 ? <i className="fas fa-check" name="customerMenopausal"></i> : ''}
                                </div>
                                <div className="d-flex" name="customerMenopausal">
                                  <h5 className="m-0" name="customerMenopausal">Yes</h5> 
                                </div>
                              </div> 
                            </div>  
                          </div>  
                          
                        </div>  
                        <div className="col-6">
                          <div className={`${props.customerDetails.customerMenopausal == 1 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerMenopausal" onClick={(e) => handleChangeInputs(e, 1)}> 
                            <div className="d-flex align-items-center justify-content-between" name="customerMenopausal">
                              <div className="d-flex align-items-center justify-content-between" name="customerMenopausal">
                                <div className={"me-3 "+styles.checkBoxCircle} name="customerMenopausal">
                                  {props.customerDetails.customerMenopausal == 1 ? <i className="fas fa-check" name="customerMenopausal"></i> : ''}
                                </div>
                                <div className="d-flex" name="customerMenopausal">
                                  <h5 className="m-0" name="customerMenopausal">No</h5> 
                                </div>
                              </div> 
                            </div>  
                          </div>  
                        </div>
                      </div>

                      <div className="col-12 mt-1">
                        <small>Post menopause is for a woman &gt;45 years who has not had any periods for &gt;12 months</small>
                      </div>
                    </div>

                    {props.customerDetails.customerMenopausal == 1 && (
                      <div className="form-group col-12">
                        <label className="position-relative" htmlFor="customerContraception" helpercolor={handleInputErrors("customerContraception", true).color}>
                          <b>Are you taking any form of hormonal contraception?</b> {handleInputErrors("customerContraception", true).icon}
                        </label>
                      
                        <div className="row">
                          <div className="col-6">
                            <div className={`${props.customerDetails.customerContraception == 2 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerContraception" onClick={(e) => handleChangeInputs(e, 2)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerContraception">
                                <div className="d-flex align-items-center justify-content-between" name="customerContraception">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerContraception">
                                    {props.customerDetails.customerContraception == 2 ? <i className="fas fa-check" name="customerContraception"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerContraception">
                                    <h5 className="m-0" name="customerContraception">Yes</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div>

                          <div className="col-6">
                            <div className={`${props.customerDetails.customerContraception == 1 ? `${styles.styledCheckbox} ${styles.active} ${!props.canEnterDetails ? styles.disabled : ""}` : styles.styledCheckbox}`} name="customerContraception" onClick={(e) => handleChangeInputs(e, 1)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerContraception">
                                <div className="d-flex align-items-center justify-content-between" name="customerContraception">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerContraception">
                                    {props.customerDetails.customerContraception == 1 ? <i className="fas fa-check" name="customerContraception"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerContraception">
                                    <h5 className="m-0" name="customerContraception">No</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div> 
                        </div> 
                      </div>
                    )}

                    {(props.customerDetails.customerMenopausal == 1 && props.customerDetails.customerContraception == 2) && (
                      <div className="form-group col-12 col-md-12">
                        <label htmlFor="customerContraceptionOptions" className="position-relative">
                          <p className="fw-bold mb-2">* Which form of contraception are you taking?</p>
                          <select disabled={props.canEnterDetails ? false : true} required onChange={handleChangeInputs} helpercolor={handleInputErrors("customerContraceptionSelected", true).color} value={props.customerDetails.customerContraceptionSelected} className="form-control required py-2" id="customerContraceptionOptions" name="customerContraceptionSelected">
                            <option value="" hidden="true">Select a contraception form</option>
                            <option disabled="disabled" default="true" defaultValue="">Select a contraception form</option>
                            <option value="11">Combined (COCP, vaginal ring)</option>
                            <option value="12">Progesterone only (Pill, Mirena, Coil, Implant, depot injection)</option>
                          </select>
                          {handleInputErrors("customerContraceptionSelected", true).icon}
                        </label>
                      </div>
                    )}

                    {props.customerDetails.customerMenopausal == 2 && (
                      <div className="form-group col-12">
                        <label htmlFor="customerHRT" className="position-relative" helpercolor={handleInputErrors("customerHRT", true).color}>
                          <b>Are you on HRT</b> {handleInputErrors("customerHRT", true).icon}
                        </label>
                      
                        <div id="customerHRT" className="row">
                          <div className="col-6">
                            <div className={`${props.customerDetails.customerHRT == 2 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerHRT" onClick={(e) => handleChangeInputs(e, 2)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerHRT">
                                <div className="d-flex align-items-center justify-content-between" name="customerHRT">
                                  <div className={"me-3 "+styles.checkBoxCircle}name="customerHRT">
                                    {props.customerDetails.customerHRT == 2 ? <i className="fas fa-check" name="customerHRT"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerHRT">
                                    <h5 className="m-0" name="customerHRT">Yes</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div>

                          <div className="col-6">
                            <div className={`${props.customerDetails.customerHRT == 1 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerHRT" onClick={(e) => handleChangeInputs(e, 1)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerHRT">
                                <div className="d-flex align-items-center justify-content-between" name="customerHRT">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerHRT">
                                    {props.customerDetails.customerHRT == 1 ? <i className="fas fa-check" name="customerHRT"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerHRT">
                                    <h5 className="m-0" name="customerHRT">No</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div> 
                        </div> 
                      </div>
                    )}
                    
                    {(props.customerDetails.customerMenopausal == 2 && props.customerDetails.customerHRT == 2) && (
                      <div className="form-group col-12">
                        <label htmlFor="customerHRTSelected" className="position-relative" helpercolor={handleInputErrors("customerHRTSelected", true).color}>
                          <b>* What type of HRT are you on?</b> {handleInputErrors("customerHRTSelected", true).icon}
                        </label>
                        {HRToptions.map( (hrt, index) => {
                          const isSelected = props.customerDetails.customerHRTSelected && Object.entries(props.customerDetails.customerHRTSelected).filter( item => item[0] == hrt.value)[0][1] || false

                          return (
                            <div key={index} className={`${isSelected ? `${styles.styledCheckbox} ${styles.selected}` : `${styles.styledCheckbox} ${styles.unselected} ${styles.hover}`} ${!props.canEnterDetails ? styles.disabled : ""} mb-2`} onClick={() => { handleChangeHRTSelected(hrt.value) }}> 
                              <div className="d-flex align-items-center justify-content-start">
                                <div className={`me-3`}>
                                  {isSelected ? <i className="fas fa-check-square fa-fw fa-lg"></i> : <i className="far fa-square fa-fw fa-lg"></i>}
                                </div>
                                <h5 className="m-0">{hrt.text}</h5>
                              </div> 
                            </div> 
                          )
                        })}
                      </div>
                    )}

                    {props.customerDetails.customerContraception == 1 && props.customerDetails.customerMenopausal != 2 && (
                      <div className="form-group col-12">
                        <label htmlFor="customerRegularPeriods" helpercolor={handleInputErrors("customerRegularPeriods", true).color}>
                          <b>Are your periods usually regular?</b> {handleInputErrors("customerRegularPeriods", true).icon}
                        </label>
                      
                        <div className="row">
                          <div className="col-6">
                            <div className={`${props.customerDetails.customerRegularPeriods == 2 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerRegularPeriods" onClick={(e) => handleChangeInputs(e, 2)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerRegularPeriods">
                                <div className="d-flex align-items-center justify-content-between" name="customerRegularPeriods">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerRegularPeriods">
                                    {props.customerDetails.customerRegularPeriods == 2 ? <i className="fas fa-check" name="customerRegularPeriods"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerRegularPeriods">
                                    <h5 className="m-0" name="customerRegularPeriods">Yes</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div>

                          <div className="col-6">
                            <div className={`${props.customerDetails.customerRegularPeriods == 1 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerRegularPeriods" onClick={(e) => handleChangeInputs(e, 1)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerRegularPeriods">
                                <div className="d-flex align-items-center justify-content-between" name="customerRegularPeriods">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerRegularPeriods">
                                    {props.customerDetails.customerRegularPeriods == 1 ? <i className="fas fa-check" name="customerRegularPeriods"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerRegularPeriods">
                                    <h5 className="m-0" name="customerRegularPeriods">No</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div> 
                        </div> 
                      </div>
                    )}

                    <RenderIf isTrue={props.customerDetails.customerMenopausal == 1 && props.customerDetails.customerRegularPeriods == 1 && props.customerDetails.customerContraception == 1}>
                      <div className="form-group col-12 col-md-12">
                        <label htmlFor="customerIsPregnant" className="position-relative" helpercolor={handleInputErrors("customerIsPregnant", true).color}>
                          <b>Are you currently pregnant?</b> {handleInputErrors("customerIsPregnant", true).icon}
                        </label>

                        <div className="row">
                          <div className="col-6">
                            <div className={`${props.customerDetails.customerIsPregnant == 2 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerIsPregnant" onClick={(e) => handleChangeInputs(e, 2)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerIsPregnant">
                                <div className="d-flex align-items-center justify-content-between" name="customerIsPregnant">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerIsPregnant">
                                    {props.customerDetails.customerIsPregnant == 2 ? <i className="fas fa-check" name="customerIsPregnant"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerIsPregnant">
                                    <h5 className="m-0" name="customerIsPregnant">Yes</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div>

                          <div className="col-6">
                            <div className={`${props.customerDetails.customerIsPregnant == 1 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerIsPregnant" onClick={(e) => handleChangeInputs(e, 1)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerIsPregnant">
                                <div className="d-flex align-items-center justify-content-between" name="customerIsPregnant">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerIsPregnant">
                                    {props.customerDetails.customerIsPregnant == 1 ? <i className="fas fa-check" name="customerIsPregnant"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerIsPregnant">
                                    <h5 className="m-0" name="customerIsPregnant">No</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div> 
                        </div> 
                      </div>

                      <div className="form-group col-12 col-md-12">
                        <label htmlFor="customerIsBreastfeeding" className="position-relative" helpercolor={handleInputErrors("customerIsBreastfeeding", true).color}>
                          <b>Are you currently breastfeeding?</b> {handleInputErrors("customerIsBreastfeeding", true).icon}
                        </label>

                        <div className="row">
                          <div className="col-6">
                            <div className={`${props.customerDetails.customerIsBreastfeeding == 2 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerIsBreastfeeding" onClick={(e) => handleChangeInputs(e, 2)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerIsBreastfeeding">
                                <div className="d-flex align-items-center justify-content-between" name="customerIsBreastfeeding">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerIsBreastfeeding">
                                    {props.customerDetails.customerIsBreastfeeding == 2 ? <i className="fas fa-check" name="customerIsBreastfeeding"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerIsBreastfeeding">
                                    <h5 className="m-0" name="customerIsBreastfeeding">Yes</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div>

                          <div className="col-6">
                            <div className={`${props.customerDetails.customerIsBreastfeeding == 1 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="customerIsBreastfeeding" onClick={(e) => handleChangeInputs(e, 1)}> 
                              <div className="d-flex align-items-center justify-content-between" name="customerIsBreastfeeding">
                                <div className="d-flex align-items-center justify-content-between" name="customerIsBreastfeeding">
                                  <div className={"me-3 "+styles.checkBoxCircle} name="customerIsBreastfeeding">
                                    {props.customerDetails.customerIsBreastfeeding == 1 ? <i className="fas fa-check" name="customerIsBreastfeeding"></i> : ''}
                                  </div>
                                  <div className="d-flex" name="customerIsBreastfeeding">
                                    <h5 className="m-0" name="customerIsBreastfeeding">No</h5> 
                                  </div>
                                </div> 
                              </div>  
                            </div>  
                          </div> 
                        </div> 
                      </div>
                    </RenderIf>

                    {props.customerDetails.customerRegularPeriods == 2 && props.customerDetails.customerContraception != 2  && (
                      <div className="form-group col-12 col-md-12"> 
                        <label htmlFor="customerAverageCycleLength" className="position-relative">
                          <p className="fw-bold mb-2">On average, what is the length of your menstrual cycle?</p>
                          <select disabled={props.canEnterDetails ? false : true} onChange={handleChangeInputs} helpercolor={handleInputErrors("customerAverageCycleLength", true).color} className="form-control required" defaultValue={props.customerDetails.customerAverageCycleLength} id="customerAverageCycleLength" name="customerAverageCycleLength" autocomplete="customerAverageCycleLength" required>
                            <option value="Less than 22">Less than 22 days</option>
                            {Array.from(Array(19).keys()).map( index => (
                              <option value={index + 22} key={index + 22}>{index + 22}</option>
                            ))}
                            <option value="More than 40">Over 40 days</option>
                          </select> 
                          {handleInputErrors("customerAverageCycleLength", true).icon}
                        </label>
                        <div className="mt-2">
                          <small>This is the time between the start of one period and the next.</small>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )} 

            <RenderIf isTrue={props.checkoutFromPartner == 1}>
              <div className="form-group"> 
                <label htmlFor="receiptEmailAddress"><b>Receipt email address</b></label>
                <input onChange={(e) => props.setReceiptEmailAddress(e.target.value)} value={props.receiptEmailAddress} type="email" className="form-control py-2" id="receiptEmailAddress" placeholder="Receipt Email Address" disabled={props.canEnterDetails ? false : true} />
              </div>
            
              <div className={`${props.clientEmailsCheck ? `${styles.styledCheckbox} ${styles.selected}` : `${styles.styledCheckbox} ${styles.unselected}`} ${!props.canEnterDetails ? styles.disabled : ""} mb-2`} 
                onClick={() => props.setClientEmailsCheck(!props.clientEmailsCheck)}> 
                <div className="d-flex align-items-center justify-content-start">
                  <div className={"me-3"}>
                    {props.clientEmailsCheck ? <i className="fas fa-check-square fa-fw fa-lg"></i> : <i className="far fa-square fa-fw fa-lg"></i>}
                  </div>
                  <h5 className="m-0">Do not send emails to my client with test details and special instructions</h5>
                </div> 
              </div> 
            </RenderIf>
            
            {guestCheckout && (
              <div className="row"> 
                <div className="form-group col-6">
                  <label htmlFor="customerFirstName" className="position-relative">
                    <p className="fw-bold mb-2">* First name:</p>
                    <input required onChange={handleChangeInputs} helpercolor={handleInputErrors("customerFirstName", true).color} value={props.customerDetails.customerFirstName} type="text" className="form-control required py-2" id="customerFirstName" name="customerFirstName" placeholder="First Name" />
                    {handleInputErrors("customerFirstName", true).icon}
                  </label>
                </div> 
                <div className="form-group col-6">
                  <label htmlFor="customerLastName" className="position-relative">
                    <p className="fw-bold mb-2">* Surname:</p>
                    <input required onChange={handleChangeInputs} helpercolor={ handleInputErrors("customerLastName", true).color} value={props.customerDetails.customerLastName} type="text" className="form-control required py-2" id="customerLastName" name="customerLastName" placeholder="Surname"/>
                    {handleInputErrors("customerLastName", true).icon}
                  </label>
                </div> 
              </div> 
            )}

            {((!props.authentication.isAuthenticated && (guestCheckout == false ||  guestCheckout == 'unset') && props.checkoutFromPartner == 0) && props.canEnterDetails) && (
              <div className="row justify-content-center py-4">
                <div className="col-12 col-md-6 text-center">
                  <h2>Choose a password to access your results</h2>
                </div>
                <div className="col-12 text-center">
                  <p>Your results are stored securely in your own private dashboard. Please enter a password so you can access your results when they’re ready</p>
                </div>
                <div className="form-group col-12 input-group">
                  <label className="position-relative" htmlFor="customerPassword">
                    <p className="fw-bold mb-2">* Password:</p>
                  </label>
                  <input disabled={props.canEnterDetails ? false : true} helpercolor={handleInputErrors("customerPassword", true).color} required onChange={handleChangeInputs} type={isPasswordVisible ? "text" : "password"} className={`form-control required py-2 ${styles.inputPassword}`} id="customerPassword" name="customerPassword" placeholder="Password"/>
                  <RenderIf isTrue={isPasswordVisible}>
                    <span className={`input-group-text ${styles.bgPrimary}`} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                      <i className="far fa-eye fa-fw text-dark"></i>
                    </span>
                  </RenderIf>
                  <RenderIf isTrue={!isPasswordVisible}>
                    <span className={`input-group-text ${styles.bgPrimary}`} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                      <i className="far fa-eye-slash fa-fw text-dark"></i>
                    </span>
                  </RenderIf>
                  {handleInputErrors("customerPassword", true).icon}
                </div> 

                <div className="col-12 text-center">
                  <p>Your password must contain at least 8 characters, at least 1 letter, 1 number, 1 special character.</p>
                </div>
              </div>
            )}  

            <RenderIf isTrue={!props.checkoutFromPartner && props.canEnterDetails && !props.guestCheckout}>
              <div className={`form-group col-12 col-md-12 mt-2 ${props.foundTrueCheckTest ? ' d-none ' : ''}`}>
                <label htmlFor="doNotReview" className="position-relative" helpercolor={handleInputErrors("doNotReview", true).color}>
                  <b>* Would you like a Forth doctor to comment on your results?</b> {handleInputErrors("doNotReview", true).icon}
                </label>

                <div className="row">
                  <div className="col-6">
                    <div className={`${props.customerDetails.doNotReview == 0 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="doNotReview" onClick={(e) => handleChangeInputs(e, 0)}> 
                      <div className="d-flex align-items-center justify-content-between" name="doNotReview">
                        <div className="d-flex align-items-center justify-content-between" name="doNotReview">
                          <div className={"me-3 "+styles.checkBoxCircle} name="doNotReview">
                            {props.customerDetails.doNotReview == 0 ? <i className="fas fa-check" name="doNotReview"></i> : ''}
                          </div>
                          <div className="d-flex" name="doNotReview">
                            <h5 className="m-0" name="doNotReview">Yes</h5> 
                          </div>
                        </div> 
                      </div>  
                    </div>  
                  </div>

                  <div className="col-6">
                    <div className={`${props.customerDetails.doNotReview == 1 ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} ${!props.canEnterDetails ? styles.disabled : ""}`} name="doNotReview" onClick={(e) => handleChangeInputs(e, 1)}> 
                      <div className="d-flex align-items-center justify-content-between" name="doNotReview">
                        <div className="d-flex align-items-center justify-content-between" name="doNotReview">
                          <div className={"me-3 "+styles.checkBoxCircle} name="doNotReview">
                            {props.customerDetails.doNotReview == 1 ? <i className="fas fa-check" name="doNotReview"></i> : ''}
                          </div>
                          <div className="d-flex" name="doNotReview">
                            <h5 className="m-0" name="doNotReview">No</h5> 
                          </div>
                        </div> 
                      </div>  
                    </div>  
                  </div> 
                </div>
              </div>


              <div className="col-12 mb-3 mt-3">
                <div className={["1", true].includes(props.customerDetails.customerOptIn) ? `${styles.styledCheckbox} ${styles.active}` : styles.styledCheckbox} onClick={() => { handleCheckboxChange('customerOptIn') }}> 
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="row">
                      <div className="col-1">
                        <div className={"me-3 "+styles.checkBoxCircle}>
                          {["1", true].includes(props.customerDetails.customerOptIn) ? <i className="fas fa-check"></i> : ''}
                        </div>
                      </div>
                      <div className="col-11">
                        <div className={styles.user_tickBoxes}>
                          <div className="row justify-content-between">
                            <div className="col-9">
                              <h3 className="fw-bold">Join our mailing list</h3>
                            </div>
                            <div className="col-3">
                              <div className="text-end">
                                <i className="fal fa-envelope-open-text"></i>
                              </div>
                            </div>
                          </div>
                          <p className="m-0">I'd like to receive product discounts and health tips from Forth. (We will never share your data with any third parties for marketing purposes)</p> 
                        </div>
                      </div>
                    </div> 
                  </div>  
                </div>  
              </div>
            </RenderIf>

            {error && (  
              <div className="col-md-12 mt-3">
                <div className="alert alert-danger basketAlert" role="alert">{errorMessage}</div> 
              </div> 
            )}

            <RenderIf isTrue={!props.guestCheckout && partnerPortalId != 0 && !props.checkoutFromPartner}>
              <GrantPartnerPermission 
                partner={props.partner} 
                customerDetails={props.customerDetails} 
                toggleGrantAccess={handleGrantPartnerPermission} 
                grantPartnerPermission={1}
              />
            </RenderIf>
        

          </form>
        </RenderIf>
        
      </div>  

      <div className="modal deleteMarkerModal" tabIndex="-1" role="dialog" id={"userDetailsAlert"}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={"modal-content shadow-sm "}>
            <div className="border-0 modal-header text-center">
              <h5 className="mt-4 fw-bold modal-title w-100 biomarkerModalTestName no-letter-spacing">Please fill in your email before proceeding</h5>
            </div> 
            <div className={"modal-body text-center"}>
              <Button className="mb-3" data-bs-dismiss="modal" aria-label="Close">
                Close
              </Button>
            </div>
          </div>
        </div>
      </div> 
    </div> 

  )
}